import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getFromLocalStorage } from "../../helpers/helpers";
const UserInfoMemorize = ({className}) => {
    const [email, setEmail] = useState()
    const [name, setName] = useState()
    const [lastName, setLastName]=useState()
    const [phoneNo, setPhoneNo]= useState()
    const [gender, setGender]= useState(null)
    useEffect(() => {
        const userEmail = getFromLocalStorage("useremail");
        const userName = getFromLocalStorage("username");
        const LastName = getFromLocalStorage("last_name");
        const phone = getFromLocalStorage("phone");
        const Gender = getFromLocalStorage("gender");
     
        setEmail(userEmail || '');
        setName(userName || '');
        setLastName(LastName || " ")
        setPhoneNo(phone || "")
        setGender(Gender || null)

        
    }, []);

    

    return (
        <div className={`${className}`}>
            <div className='w-full bg-white shadow-xl p-10'>
                <fieldset className='border border-[#dbdbdb] px-2'>
                    <legend className='px-2'>
                        <div className='flex space-x-7'>
                            <h1 className="font-semibold text-[18px] text-black">Besic Information</h1>
                            <Link to={"/profile/edit"} className='font-semibold text-[#2874F0] mt-1 cursor-pointer'>Edit</Link>
                        </div>
                    </legend>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-5 pl-2 mt-2'>
                        <h1><span className="font-semibold text-[18px] text-black">Name:</span> {name} {lastName}</h1>
                    
                        {/* <input className='focus:outline-none border border-[#E0E0E0] bg-[#FAFAFA] rounded-sm p-2 w-full' type='text' value={name} placeholder={name} />
                        <input className='focus:outline-none border border-[#E0E0E0] bg-[#FAFAFA] rounded-sm p-2 w-full' type='text' value={lastName} placeholder={lastName} /> */}
                    </div>
                    <div className='tex-sm text-black pl-2 mt-2'>
                        <h1><span className="font-semibold text-[18px] text-black">Gender:</span> {gender}</h1>
                        {/* <div className='flex space-x-7'>
                        <div className='grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-5'>
                            <div className='sm:mt-4'>
                                <input className='w-[16px] h-[16px] text-[#C2C2C2]' type='radio' checked={gender === "male"?true:false}  name='male' />
                                <label className='ml-5 text-lg text-[#C2C2C2]'>Male</label>
                            </div>
                            <div className='sm:mt-4'>
                                <input className='w-[16px] h-[16px] text-[#C2C2C2]' type='radio' checked={gender === "female"?true:false}  name='male' />
                                <label className='ml-5 text-lg text-[#C2C2C2]'>Female</label>
                            </div>
                            <div className='sm:mt-4'>
                                <input className='w-[16px] h-[16px] text-[#C2C2C2]' type='radio' checked={gender === "Other"?true:false}  name='male' />
                                <label className='ml-5 text-lg text-[#C2C2C2]'>Other</label>
                            </div>
                        </div> */}
                    </div>
                </fieldset>
                <div className='mt-10'>
                    <div className='flex space-x-7'>
                        <h1 className="text-black"><span className="font-semibold text-[18px] text-black">Email Address:</span> {email}</h1>
                        {/* <p className='font-semibold text-[#2874F0] mt-1 cursor-pointer'>Edit</p> */}
                    </div>
                    {/* <input className='focus:outline-none border mt-7 border-[#E0E0E0] bg-[#FAFAFA] rounded-sm p-2 w-[258px] h-[50px]' type='email' value={email} placeholder={email} /> */}
                </div>
                <div className='mt-4'>
                    <div className='flex space-x-7'>
                        <h1 className="text-black"><span className="font-semibold text-[18px] text-black">Mobile Number:</span> {phoneNo}</h1>
                        {/* <p className='font-semibold text-[#2874F0] mt-1 cursor-pointer'>Edit</p> */}
                    </div>
                    {/* <input className='focus:outline-none border mt-7 border-[#E0E0E0] bg-[#FAFAFA] rounded-sm p-2 w-[258px] h-[50px]' value={phoneNo} type='text' placeholder={phoneNo} /> */}
                </div>
            </div>
        </div>
    )
}
const UserInfo = memo(UserInfoMemorize)
export default UserInfo
