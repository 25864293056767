import React, { useState } from 'react';
import "./style.css";

const AccItem = ({ handleItemClick, onClick, className, orderID, title, children, payMode, payAmount }) => {
  const [isOpen, setIsOpen] = useState(false); // Initial state: closed

  const handleClick = (index) => {
    console.log('hello world')
    setIsOpen(!isOpen); // Toggle open/closed state 50k ap dev ml
    // handleItemClick(index, isOpen); // Toggle open/closed state
  };

  return (
    <>
      <div className="accordion-item">
        {children}
      </div>
    </>
  );
};

export default AccItem;

AccItem.Header = (props) => <div className={`order-card-header ${props.className}`}  >{props.children}</div>
AccItem.Body = (props) => <div className={`order-card-body ${props.visibility}`}  >{props.children}</div>
AccItem.Footer = (props) => <div className={`order-card-footer ${props.className}`} onClick={props.onClick} >{props.children}</div>
