import React from 'react'
import Layout from '../../components/layout/Layout'

const ReturnOrExchangepolicy = () => {
  return (
    <Layout>
      <body className="bg-gray-100 font-sans">
        <header className="bg-red-600 text-white p-4">
          <div className="container mx-auto">
            <h1 className="text-2xl font-semibold">Return or Exchange Policy</h1>
          </div>
        </header>

        <div className="container mx-auto my-8 p-8 bg-white rounded shadow-lg">


          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Introduction</h2>
            <p className="text-gray-600">
              We want you to be satisfied with your purchase. If you are not completely happy, you may return
              or exchange your item(s) within the specified period.
            </p>
          </section>


          <section>
            <h2 className="text-xl font-semibold mb-4">1. Return Process</h2>
            <p className="text-gray-600">
              To return an item, please follow these steps:
            </p>
            <ol className="list-decimal ml-6 text-gray-600">
              <li>Log in to your account and go to the "Order History" section.</li>
              <li>Select the order containing the items you want to return.</li>
              <li>Follow the instructions to initiate the return process.</li>
            </ol>

            <h2 className="text-xl font-semibold mt-6 mb-4">2. Exchange Process</h2>
            <p className="text-gray-600">
              To exchange an item, please follow these steps:
            </p>
            <ol className="list-decimal ml-6 text-gray-600">
              <li>Log in to your account and go to the "Order History" section.</li>
              <li>Select the order containing the items you want to exchange.</li>
              <li>Follow the instructions to initiate the exchange process.</li>
            </ol>



          </section>

        </div>



      </body>
    </Layout>
  )
}

export default ReturnOrExchangepolicy
