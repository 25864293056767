import React from 'react'
import Layout from '../../components/layout/Layout'
import { Link } from 'react-router-dom'
import { getFromLocalStorage, removeAllFromLocalStorage, setToLocalStorage } from "../../helpers/helpers";

const EmailConfirmation = () => {
    return (
        <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-8 bg-white">
                <div className="max-w-xl px-5 text-center">
                    <h2 className="mb-2 text-[42px] font-bold text-zinc-800">Check your inbox</h2>
                    <p className="mb-2 text-lg text-zinc-500">We are glad, that you’re with us ? We’ve sent you a verification link to the email address <span className="font-medium text-indigo-500">{getFromLocalStorage("forgetpasswordemail")}</span>.</p>
                    <Link to={'/'}><button className="mt-3 inline-block w-96 rounded bg-red-600 px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 hover:bg-red-700">Back To Homepage →</button></Link>
                </div>
        </div>
    )
}

export default EmailConfirmation
