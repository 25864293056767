import React,{memo} from 'react'
import Layout from '../../components/layout/Layout'
import ProductDetailsPage from '../productDetailsPage/ProductDetailsPage'

const sareeMemorize = () => {
  return (
    <Layout>
      <ProductDetailsPage/>
    </Layout>
  )
}
const saree = memo(sareeMemorize)
export default saree