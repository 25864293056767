import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiBaseUrl } from '../../config/config'
import axios from 'axios'
import { getFromLocalStorage } from "../../helpers/helpers";
import Layout from '../../components/layout/Layout'


const EditShipingAddress = ({className}) => {
    const navigate = useNavigate()
    const [name, setName] = useState()
    const [address, setAddress] = useState()
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [landmark, setLandmark] = useState()
    const [phone, setPhone] = useState()
    const [zip, setZip] = useState()
    const [id, setId]=useState()
    const [defaultShipping, setDefaultShipping]=useState()
    const [token, setToken] = useState(getFromLocalStorage("access_token"))
    const [addDefaultCheck, setAddDefaultCheck] = useState()

    const [addressFormError, setAddressformError] = useState()
    const [MsgEditShipAddSuccess, setMsgEditShipAddSuccess] = useState()

    // console.log('check 1', id.toString(), defaultShipping.toString(), addDefaultCheck)
    // console.log('check 1', addDefaultCheck, getFromLocalStorage('default_shipping'))
    useEffect(() => {
        console.log("addressformerror", addressFormError)       
    }, [addressFormError])

    let states = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"
    ]
    const stateNameList = () => {
        return (
            states.map((item, index) => {
                return (
                    <option >{item}</option>
                )
            })
        )
    }
    useEffect(() => {
        setName(localStorage.getItem("aname"))
        setAddress(localStorage.getItem("aaddress"))
        setState(localStorage.getItem("astate"))
        setCity(localStorage.getItem("acity"))
        setLandmark(localStorage.getItem("alandmark"))
        setZip(localStorage.getItem("azip"))
        setPhone(localStorage.getItem("aphone"))
        setId(localStorage.getItem("addressId"))
        setDefaultShipping(localStorage.getItem("default_shipping"))
    }, [])

    const payload ={
        name,
        address,
        landmark,
        state,
        city,
        zip,
        phone,
        default_shipping:defaultShipping ?1:0
    }
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const submitHandler = (e) => {
        e.preventDefault()
        axios.put(`${apiBaseUrl}api/update_address/${id}`, payload, { headers })
            .then((res) => {
                setMsgEditShipAddSuccess(res.data.message)
                setTimeout(() => {
                    navigate('../../profile/shippingadress')
                }, 5000)
            })
            .catch((err) => {
                var errors= {}
                console.log("This is error", err, err.response.data.message)
                errors.address = err.response.data.message?.address?.join(", ") 
                errors.state = err.response.data.message?.state?.join(", ")
                errors.city = err.response.data.message?.city?.join(", ")
                errors.landmark = err.response.data.message?.landmark?.join(", ")
                errors.zip = err.response.data.message?.zip?.join(", ")
                errors.phone = err.response.data.message?.phone?.join(", ")
                errors.name = err.response.data.message?.name?.join(", ")
                errors.email = err.response.data.message?.email?.join(", ")
                setAddressformError(errors)
            })

    }

    // console.log('check 2', addDefaultCheck)
    // useEffect(() => {
    //     setAddDefaultCheck(id == defaultShipping ? true:false)
    // }, [])
    // console.log('check 3', addDefaultCheck)
    const checkDefaultShipping = () => {
        // console.log('Check address', id == defaultShipping ? true:false)
        // setAddDefaultCheck(addressIDstate)
        // console.log(addressID + ' == ' + defaultShipping + ':' + addDefaultCheck)
        // setAddDefaultCheck(!addDefaultCheck)
        // console.log('Check address', addDefaultCheck)
    }
    // console.log('Check address', id == defaultShipping ? true:false)
    

    return (
        <div className={className}>
            {MsgEditShipAddSuccess ? 
            <div className='message fixed bottom-0 left-[50%] -translate-x-[50%] z-9 bg-[#c4e2c4] rounded-t-md py-[.7rem] px-[1.2rem]'>
                <h1 className='text-2xl font-normal text-gray-900'>{MsgEditShipAddSuccess}</h1>
            </div>
            :''}
            <form onSubmit={submitHandler} className='w-full bg-white shadow-xl p-10'>
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive order.</p>

                    <div className="mt-10 grid grid-cols-1 sm:grid-cols-6 gap-x-6 gap-y-8">
                        <div className="sm:col-span-3 col-start-1">
                            <label for="first-name" className="block text-sm font-medium leading-6 text-gray-900">First name</label>
                            <div className="mt-2">
                                <input type="text" name="first-name" value={name} onChange={(e)=>setName(e.target.value)} id="first-name" autocomplete="given-name" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <small className='text-red-600'>{addressFormError?.name}</small>
                        </div>

                        <div className="sm:col-span-3 col-start-1">
                            <label for="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                            <div className="mt-2">
                                <input id="email" name="email" value="" onChange="" type="email" autocomplete="email" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <small className='text-red-600'>{addressFormError?.email}</small>
                        </div>



                        <div className="sm:col-span-3 col-start-1">
                            <label for="country" className="block text-sm font-medium leading-6 text-gray-900">State</label>
                            <div className="mt-2">
                                <select id="State" name="State" value={state} onChange={(e) =>setState(e.target.value)} autocomplete="State-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">

                                    {stateNameList()}
                                </select>
                            </div>
                        </div>

                        <div className="sm:col-span-3 col-start-1">
                            <label for="street-address" className="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                            <div className="mt-2">
                                <input type="text" value={phone} name="street-address" onChange={(e) =>setPhone(e.target.value.slice(0,10))} id="street-address" autocomplete="street-address" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <small className='text-red-600'>{addressFormError?.phone}</small>
                        </div>

                        <div className="col-span-full">
                            <label for="street-address" className="block text-sm font-medium leading-6 text-gray-900">Street address</label>
                            <div className="mt-2">
                                <textarea 
                                    type="text" 
                                    value={address} 
                                    name="street-address" 
                                    onChange={(e) =>setAddress(e.target.value)} 
                                    id="street-address" autocomplete="street-address" 
                                    className="block w-full  min-h-[150px] rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                >
                                </textarea>
                            </div>
                            <small className='text-red-600'>{addressFormError?.address}</small>
                        </div>

                        <div className="sm:col-span-2 sm:col-start-1">
                            <label for="city" className="block text-sm font-medium leading-6 text-gray-900">City</label>
                            <div className="mt-2">
                                <input type="text" name="city" value={city} onChange={(e) =>setCity(e.target.value)} id="city" autocomplete="address-level2" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <small className='text-red-600'>{addressFormError?.city}</small>
                        </div>

                        <div className="sm:col-span-2">
                            <label for="region" className="block text-sm font-medium leading-6 text-gray-900">Land Mark</label>
                            <div className="mt-2">
                                <input type="text" name="landmark" value={landmark} onChange={(e) =>setLandmark(e.target.value)} id="region" autocomplete="address-level1" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <small className='text-red-600'>{addressFormError?.landmark}</small>
                        </div>

                        <div className="sm:col-span-2">
                            <label for="postal-code" className="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code</label>
                            <div className="mt-2">
                                <input type="text" name="postal-code" value={zip} onChange={(e) =>setZip(e.target.value)} id="postal-code" autocomplete="postal-code" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                            <small className='text-red-600'>{addressFormError?.zip}</small>
                        </div>
                    </div>
                </div>
                <div className='flex mt-5'>
                    {defaultShipping} - {id} - {localStorage.getItem("default_shipping")}{/* value={defaultShipping}  */}
                    <input type='checkbox'  onChange={(e)=>setDefaultShipping(e.target.checked)} className='mr-5' />
                    <p> Set Default shipping address</p>
                </div>
                <div className="block min-h-[1.5rem] pl-[1.5rem] mb-[.125rem]">
                    <input defaultChecked={addDefaultCheck} onChange={() => {
                        // setAddDefaultCheck(id == defaultShipping ? true:false)
                        // checkDefaultShipping(id == defaultShipping ? true:false)
                        // checkDefaultShipping()
                        setAddDefaultCheck(!addDefaultCheck)
                    }} type="checkbox" className="form-check-input"  id="gridCheck" />
                    <label className="form-check-label" htmlFor="gridCheck">
                        Check me out
                    </label>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6 pb-10">
                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => navigate(-1)}>Cancel</button>
                    <button type="submit" className="rounded-md bg-red-600 w-28 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                </div>
            </form>
        </div>
    )
}

export default EditShipingAddress