import React,{memo} from 'react';

import Carousel2 from '../carousel2/Carousel2';
import Fade from 'react-reveal/Fade';
const BannerSectionMemorize = () => {
    return (
        <div className='bg-white'>

            <Carousel2 />
            <div className='bg-red-500 h-10 flex justify-center items-center min-w-full md:w-[70%]'>
                {/* <marquee behavior="alternate" className='text-white text-[14px] font-thin '>Gariahat: 198, Rash Behari Ave, Golpark, Hindustan Park, Ballygunge, Kolkata, West Bengal 700029 Saltlake: BE-5, near Quality bus stop, BE Block, Sector-01, Bidhannagar, Kolkata, West Bengal 700064</marquee> */}
                <div className="relative flex overflow-x-hidden md:w-[55rem]">
                    <div className="py-12 animate-marquee whitespace-nowrap">
                        <span className="text-white text-[14px] font-thin ">BE - 5,</span>
                        <span className="text-white text-[14px] font-thin "> Sector I,</span>
                        <span className="text-white text-[14px] font-thin "> Salt Lake,</span>
                        <span className="text-white text-[14px] font-thin "> Kol- 700064</span>
                        <span className="text-white text-[14px] font-thin "></span>
                        <span className="text-white text-[14px] font-thin mx-4">(On Kwality main road)</span>
                    </div>
                    <div className="absolute py-12 animate-marquee2 whitespace-nowrap">
                        <span className="text-white text-[14px] font-thin ">198,</span>
                        <span className="text-white text-[14px] font-thin "> Rash Behari</span>
                        <span className="text-white text-[14px] font-thin "> Avenue Kol &nbsp;</span>
                        <span className="text-white text-[14px] font-thin ">- 700029</span>
                        <span className="text-white text-[14px] font-thin "></span>
                        <span className="text-white text-[14px] font-thin mx-4">
                        (Oppo. Basanti Devi College)</span>
                    </div>
                </div>
            </div>
            {/* <div className='w-full h-28 flex justify-center items-center static'>
                <img src={Bestsellers} alt='bestSellers' />

            </div>
            <div className='flex justify-center items-center text-center pb-10'>
            <Fade top cascade>
                <p className='text-sm w-[50%]'>Our curated collection of bestselling sarees that redefine elegance and style. Our handpicked selection embodies the perfect blend of tradition and contemporary fashion.</p>
            </Fade>
            </div> */}
        </div>
    )
}
 const BannerSection = memo(BannerSectionMemorize)
export default BannerSection