import { createSlice } from '@reduxjs/toolkit'

/*
* user intial state
*/
const initialState = {
  productList: [],
}

/*
* Slice
*/
const slice = createSlice({
  name: 'productslist',
  initialState,
  reducers: {
    setProductList: (state, action) => {
      state.productList = action.payload.page === 1 ? action.payload : state.productList.concat(action.payload);
    }
  },
});

export const {
    setProductList,
} = slice.actions

export default slice.reducer