/**
 * Helpers Functions
 */

import SecureLS from 'secure-ls'

var ls = new SecureLS();

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}




/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
    let location = url.pathname;
    let path = location.split('/');
    return path[1];
}

/*
* set data in Local storage in encrypt mode
*/
export function setToLocalStorage(key, data) {
    ls.set(key, data);
}

/*
* get data from local storage
*/
export function getFromLocalStorage(key) {
    let dataFromStorage = ls.get(key);
    return dataFromStorage;
}

/*
* remove data from local storage
*/
export function removeFromLocalStorage(key) {
    ls.remove(key)
}

/*
* remove all keys from storage
*/
export function removeAllFromLocalStorage() {
    ls.removeAll()
}

export function getColor(key) {
    let color = ["#90AFC5", "#AEBD38", "#C4DFE6", "#98DBC6", "#5BC8AC", "#A1D6E2", "#1995AD", "#9A9EAB", "#EC96A4", "#F52549", "#FA6775", "#CDCDC0", "#DDBC95", "#92AAC7", "#C1E1DC", "#FFCCAC", "#F4EC6A", "#BBCF4A", "#52958B", "#97B8C2", "#FFBEBD", "#138D90", "#8593AE", "#ED8C72", "#D3DE81"];
    return (color.length <= key) ? color[key % color.length] : color[key];
}

export function base64ToText(base64) {
    let binary = atob(base64);
    return decodeURIComponent(Array.prototype.map.call(binary, function (char) {
        return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}


// generate Random password
export function generateP(setValue, value) {
    var pass = '';
    var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
        'abcdefghijklmnopqrstuvwxyz0123456789@#$';

    for (let i = 1; i <= 8; i++) {
        var char = Math.floor(Math.random()
            * str.length + 1);

        pass += str.charAt(char)
    }
    setValue({ ...value, password: pass });
    return pass;
}