import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import axios from 'axios';
import { apiBaseUrl } from '../../config/config';
import { getFromLocalStorage, setToLocalStorage } from "../../helpers/helpers";


import { Box } from '@mui/joy'

function EditUserProfile({className}) {
    const [email, setEmail] = useState()
    const [userID, setUserID] = useState(getFromLocalStorage("userId"))
    const [name, setName] = useState(getFromLocalStorage("username"))
    const [lastName, setLastName]=useState(getFromLocalStorage("last_name"))
    const [phoneNo, setPhoneNo]= useState()
    const [gender, setGender]= useState(getFromLocalStorage("gender"))
    const [MsgEditUserProfileSuccess, setMsgEditUserProfileSuccess] = useState()
    const [MsgEditUserProfileError, setMsgEditUserProfileError] = useState()


    const [isValidField, setIsValidField] = useState(false)

    /* useEffect(() => {
        const userEmail = getFromLocalStorage("useremail");
        const uID = getFromLocalStorage("userId");
        const userName = getFromLocalStorage("username");
        const LastName = getFromLocalStorage("last_name");
        const phone = getFromLocalStorage("phone");
        const Gender = getFromLocalStorage("gender");
     
        // setEmail(userEmail || '');
        setUserID(uID || '');
        setName(userName || '');
        setLastName(LastName || " ")
        // setPhoneNo(phone || "")
        setGender(Gender || null)        
    }, []); */


    
    const navigate = useNavigate();

    const [info, setInfo] = useState({ name: getFromLocalStorage("username"), last_name: getFromLocalStorage("last_name"), gender: getFromLocalStorage("gender") });
    const handleInputChange = (field, value) => {
        setInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
        console.log('Post Data', info)
    };


    const submitHandler = (e) => {
        e.preventDefault();
        const headers = {
            'Authorization': `Bearer ${getFromLocalStorage("access_token")}`,
            'Content-Type': 'application/json',
        };
        const { name, last_name, gender } = info;
        // axios.post(`${apiBaseUrl}api/register`, {
        axios.put(`${apiBaseUrl}api/updateprofile/${userID}`, {
            name: name,
            last_name: last_name,
            gender: gender
        }, {headers}).then((res) => {
            // navigate('/login');
            setToLocalStorage("username", name)
            setToLocalStorage("last_name", last_name)
            setToLocalStorage("gender", gender)
            console.log('Success', res.data.message)
            if (res.data?.message) {
                setMsgEditUserProfileSuccess(res.data.message)
                setTimeout(() => {
                    navigate('/profile')
                }, 5000)
                
            }
      
        })
        .catch((err) => {
            var errors= {}
            console.log("This is error", err, err.response.data.message)
            errors.name = err.response.data.message?.name?.join(", ")
            errors.last_name = err.response.data.message?.last_name?.join(", ")
            errors.gender = err.response.data.message?.gender?.join(", ")
            // setAddressformError(errors)
            setMsgEditUserProfileError(errors)
        });
    };
    // console.log('User Info', userID, info)
  return (
    <>
        <div className={`${className}`}>
            {MsgEditUserProfileSuccess ? 
            <div className='message fixed bottom-0 left-[50%] -translate-x-[50%] z-9 bg-[#c4e2c4] rounded-t-md py-[.7rem] px-[1.2rem]'>
                <h1 className='text-2xl font-normal text-gray-900'>{MsgEditUserProfileSuccess}</h1>
            </div>
            :''}
            <div className='w-full bg-white shadow-xl p-10'>

                <div className='grid grid-cols-1 sm:grid-cols-6 gap-x-6 gap-y-8'>
                    <div className="sm:col-span-3 col-start-1">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 undefined">
                            First Name
                        </label>
                        <div className="flex flex-col items-start">
                            <input type="text" name="name" className="block w-full mt-1 border-gray-300  bg-red-100 h-8 focus:outline-none px-5 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" defaultValue={name} onChange={(e) => {
                                setIsValidField(e.target.value.length>0);
                                handleInputChange('name', e.target.value);
                            }} />
                        </div>
                        {MsgEditUserProfileError?.name ? <small className='text-red-600'>{MsgEditUserProfileError?.name}</small>:''}
                    </div>
                    <div className="sm:col-span-3 col-start-1">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 undefined">
                            Last Name
                        </label>
                        <div className="flex flex-col items-start">
                            <input type="text" name="name" className="block w-full mt-1 border-gray-300  bg-red-100 h-8 focus:outline-none px-5 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" defaultValue={lastName} onChange={(e) => {
                                setIsValidField(e.target.value.length>0);
                                handleInputChange('last_name', e.target.value);
                            }} />
                        </div>
                        {MsgEditUserProfileError?.last_name ? <small className='text-red-600'>{MsgEditUserProfileError?.last_name}</small>:''}
                    </div>
                    {/* <div className="sm:col-span-3 col-start-1">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 undefined">
                            Email
                        </label>
                        <div className="flex flex-col items-start">
                            <input type="email" name="email" className="block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                        </div>
                        <small className='text-red-600'>Custom Error msg</small>
                    </div> */}
                    <div className='sm:col-span-3 col-start-1 tex-sm'>
                        <label className="block text-sm font-medium text-gray-700">Gender:</label>
                        <div className='grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-5'>
                            <div className='sm:mt-4'>
                                <label className='ml-5'><input className='w-[16px] h-[16px] text-[#C2C2C2]' type='radio' name="gender" checked={gender === "male"} onChange={(e) => { 
                                    handleInputChange('gender', e.target.value)
                                    setGender('male')
                                }} value='male' /> Male</label>
                            </div>
                            <div className='sm:mt-4'>
                                <label className='ml-5'><input className='w-[16px] h-[16px] text-[#C2C2C2]' type='radio' name="gender" checked={gender === "female"} onChange={(e) => { 
                                    handleInputChange('gender', e.target.value)
                                    setGender('female')
                                }} value='female' /> Female</label>
                            </div>
                            <div className='sm:mt-4'>
                                <label className='ml-5'><input className='w-[16px] h-[16px] text-[#C2C2C2]' type='radio' name="gender" checked={gender === "Other"} onChange={(e) => { 
                                handleInputChange('gender', e.target.value)
                                setGender('Other')
                            }} value='Other' /> Other</label>
                            </div>
                        </div>
                    </div>
                    {/* <div className="sm:col-span-3 col-start-1">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 undefined">
                            Password
                        </label>
                        <div className="flex flex-col items-start">
                            <input type="password" name="password" className="block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                        </div>
                        <small className='text-red-600'>Custom Error msg</small>
                    </div>
                    <div className="sm:col-span-3 col-start-1">
                        <label htmlFor="password_confirmation" className="block text-sm font-medium text-gray-700 undefined">
                            Confirm Password
                        </label>
                        <div className="flex flex-col items-start">
                            <input type="password" name="password_confirmation" className="block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                        </div>
                        <small className='text-red-600'>Custom Error msg</small>
                    </div> */}
                    <div className="col-span-6 flex items-center space-x-3 mt-4">
                        <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600" onClick={submitHandler}>
                            Update
                        </button>
                        <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600" onClick={() => navigate(-1)}>
                            Cancel
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </>
  )
}

export default EditUserProfile