import React from 'react'
import Layout from '../../components/layout/Layout'
import { IoWarningOutline } from "react-icons/io5";
import { BsCreditCard2Back } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { IoMdPaper } from "react-icons/io";
import { GoX } from "react-icons/go";
const Aborted = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const amount = searchParams.get("amount");
    const orderId = searchParams.get("orderid");
    return (
        <Layout>
                   <div className='h-screen flex justify-center items-center  bg-gray-200 '>
                <div className='bg-white w-[70%] h-[70%] shadow-2xl rounded-md '>
                    <div className='bg-white h-60 w-60 rounded-full p-5  mx-auto -mt-20 relative'>
                        <div className='w-full h-full rounded-full bg-[#FBB0BF] p-5'>
                            <div className='w-full h-full rounded-full bg-[#FE466B] flex justify-center items-center'>
                                <IoWarningOutline className='text-white text-[80px]' />
                            </div>
                            <div className=' bg-white h-20 w-20 rounded-full absolute bottom-5 right-2 p-1'>
                                <div className='w-full h-full rounded-full bg-[#FE466B] flex justify-center items-center'>
                                    <GoX className='text-white text-[50px]' />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='w-full flex justify-center mt-8'>
                        <p className='font-bold text-black text-4xl mx-auto tracking-wider'>₹ {amount}</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className='font-bold text-black text-3xl font-serif mt-7'>payment aborted!</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-black text-xl font-serif mt-2'>Hey, seems like you canceled your payment</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-black text-xl font-serif mt-2 '>We are therewith you. try again</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-gray-400 text-xl mt-8 '>Payment ID : {orderId}</p>
                    </div>
                    <div className='w-full flex justify-center mt-5'>
                        <Link to={'/cart'}><button className='bg-[#0B6A4A] w-40 rounded h-10 text-white  text-lg'>TRY AGAIN</button></Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Aborted