import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import Box from '@mui/joy/Box';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
// import Link from '@mui/joy/Link';
import Chip from '@mui/joy/Chip';
// import AdjustIcon from '@mui/icons-material/Adjust';
import { BsInfoCircle } from "react-icons/bs";
import "./style.css"


import Logo from '../../assets/Logo/Logo.png';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';
import { apiBaseUrl } from '../../config/config';
import Layout from '../../components/layout/Layout';

const Signup = () => {
    // field validation states
    const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false)
    const [isValidField, setIsValidField] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [isValidMobile, setIsValidMobile] = useState(false)
    const [addressFormError, setAddressformError] = useState()

    // password validation states
    const [password, setPassword] = useState("");
    const [hasLowerCase, setHasLowerCase] = useState(false)
    const [hasUpperCase, setHasUpperCase] = useState(false)
    const [hasNumber, setHasNumber] = useState(false)
    const [hasSpecialChar, setHasSpecialChar] = useState(false)
    const [hasMinimumLength, setHasMinimumLength] = useState(false)

    useEffect(() => {
        if(password){
          setHasLowerCase(password.match(/[a-z]/));
          setHasUpperCase(password.match(/[A-Z]/));
          setHasNumber(password.match(/[0-9]/));
          setHasSpecialChar(password.match(/[!@#$%^&*()_+\-=\[\]{};':\\|,.<>/?]/));
          setHasMinimumLength(password?.length >= 8);
        }
      }, [password]);

      useEffect(() => {
            console.log("addressformerror", addressFormError)       
      }, [addressFormError]);

    const [info, setInfo] = useState({ name: '', last_name: '', email: '', phone: '', password: '', confirmPassword: '', gender: 'male' });
    const navigate = useNavigate();

    const handleInputChange = (field, value) => {
        setInfo((prevInfo) => ({ ...prevInfo, [field]: value }));
    };


    const submitHandler = (e) => {
        e.preventDefault();
        const { name, last_name, email, phone, password, confirmPassword, gender } = info;
        axios.post(`${apiBaseUrl}api/register`, {
            name,
            email,
            phone:"+91"+phone,
            password,
            password_confirmation: confirmPassword,
            last_name,
            gender
        }).then((res) => {
                // navigate('/login');
                navigate('/profile');
            })
            .catch((err) => {
                var errors= {}
                console.log("This is error", err, err.response.data.message)
                errors.email = err.response.data.message?.email?.join(", ")
                errors.password = err.response.data.message?.password?.join(", ")
                errors.name = err.response.data.message?.name?.join(", ")
                errors.phone = err.response.data.message?.phone?.join(", ")
                errors.last_name = err.response.data.message?.last_name?.join(", ")
                errors.gender = err.response.data.message?.gender?.join(", ")
                setAddressformError(errors)
            });
    };

    return (
        <Layout>
            <div className="bg-gray-200 flex flex-col items-center min-h-screen sm:justify-center py-10">
                {/* <div>
                    <Link to="/">
                        <h3 className="text-4xl font-bold text-red-600">MABESHA</h3>
                    </Link>
                </div> */}
                <div className="text-center mb-10">
                    <img className="mx-auto w-48" src={Logo} alt="logo" />
                </div>
                <div className="w-full space-y-3 px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-lg sm:rounded-lg">
                <div className='flex space-y-5 sm:space-y-0 sm:space-x-5 flex-col sm:flex-row'>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 undefined">
                            First Name
                        </label>
                        <div className="flex flex-col items-start">
                            <input
                                value={info.name}
                                onChange={(e) => {
                                    setIsValidField(e.target.value.length>0);
                                    handleInputChange('name', e.target.value);
                                }}
                                type="text"
                                name="name"
                                className={`block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50  border ${addressFormError?.name?"border-red-600":" "}   `}
                            />
                        </div>
                        {addressFormError?.name?<></>:<small className='text-red-600'>{addressFormError?.name}</small>}
                    </div>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 undefined">
                            Last Name
                        </label>
                        <div className="flex flex-col items-start">
                            <input
                                value={info.last_name}
                                onChange={(e) => handleInputChange('last_name', e.target.value)}
                                type="text"
                                name="last_name"
                                className="block w-full mt-1 border-gray-300 bg-red-100 h-8 focus:outline-none px-5 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50    "
                            />
                        </div>
                        {addressFormError?.last_name?<small className='text-red-600'>{addressFormError?.last_name}</small>:<></>}
                    </div>
                </div>
                <div>
                    <label htmlFor="gender" className="block text-sm font-medium text-gray-700 undefined">
                        Gender
                    </label>
                    <div className="flex flex-col items-start">
                        <select
                            name="gender"
                            value={info.gender}
                            onChange={(e) => {
                                handleInputChange("gender", e.target.value);
                            }}
                            className="block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50    "
                        >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                </div>
                <div className='flex space-y-5 sm:space-y-0 sm:space-x-5 flex-col sm:flex-row'>
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 undefined"
                        >
                            Email
                        </label>
                        <div className="flex flex-col items-start">
                            <input
                                type="email"
                                name="email"
                                value={info.email}
                                onChange={(e) => {
                                    setIsValidEmail(e.target.value?.includes("@")&&e.target.value?.includes("."))
                                    handleInputChange("email", e.target.value)
                                }}
                                className={`block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50  border ${addressFormError?.email?"border-red-600":" "}   `}
                            />
                        </div>
                        {addressFormError?.email?<small className='text-red-600'>{addressFormError?.email} </small>:<></>}
                    </div>
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 undefined"
                        >
                            Phone
                        </label>
                        <div className="flex flex-col items-start">
                            <input
                                type="text"
                                name="phone"
                                value={info.phone}
                                onChange={(e) => {
                                    setIsValidMobile(e.target.value?.toString()?.length>=10)
                                    handleInputChange("phone", e.target.value)
                                }}
                                className={`block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50  border ${addressFormError?.phone?"border-red-600":""}   `}
                            />
                        </div>
                        {addressFormError?.phone?<small className='text-red-600'>{addressFormError?.phone}</small>:<></>}

                    </div>
                </div>
                <div>

                </div>
                <div>
                    <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700 undefined"
                    >
                    Password
                    </label>
                    <div className="flex flex-col items-start mt-1 relative">
                        <input
                            type="password"
                            name="password"
                            value={info.password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                handleInputChange("password", e.target.value)
                            }}
                            className={
                                `block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 
                                shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50  border 
                                ${(hasLowerCase&&hasUpperCase&&hasNumber&&hasSpecialChar&&hasMinimumLength&&addressFormError?.password?false:true)?"":" border-red-600"}   `}
                        />
                        {(addressFormError?.password?false:true)?
                        <></>
                        :
                        <Tooltip
                            placement="top-end"
                            variant="outlined"
                            arrow
                            title={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        maxWidth: 320,
                                        justifyContent: 'center',
                                        p: 1,
                                    }}
                                    >
                                    <Box sx={{ display: 'flex', gap: 1, width: '100%', mt: 1 }}>

                                        <div>
                                        <Typography fontWeight="lg" fontSize="sm">
                                            Password Instruction
                                        </Typography>
                                        <Typography textColor="text.secondary" fontSize="sm" sx={{ mb: 1 }}>
                                            <ul className='passInstruction'>
                                                <li className={hasLowerCase?'checked':''}>Minimum 1 Small </li>
                                                <li className={hasUpperCase?'checked':''}>Minimum 1 Capital</li>
                                                <li className={hasSpecialChar?'checked':''}>Minimum 1 Special character</li>
                                                <li className={hasNumber?'checked':''}>Minimum 1 number</li>
                                                <li className={hasMinimumLength?'checked':''}>Total 8 character</li>
                                            </ul>
                                        </Typography>

                                        </div>
                                    </Box>
                                </Box>
                            }
                        >
                            <Link href="#common-examples" underline="none" className="passInst absolute top-2 right-2">
                                <BsInfoCircle className='text-[#f00]' />
                            </Link>  
                        </Tooltip>}
                    </div>
                    {(hasLowerCase&&hasUpperCase&&hasNumber&&hasSpecialChar&&hasMinimumLength&&addressFormError?.password?.length==0)?<></>
                    :
                    <small className='text-red-600'>{addressFormError?.password} </small>}
                </div>
                <div>
                    <label
                        htmlFor="password_confirmation"
                        className="block text-sm font-medium text-gray-700 undefined"
                    >
                        Confirm Password 
                    </label>
                    <div className="flex flex-col items-start mt-1">
                        <input
                            type="password"
                            name="password_confirmation"
                            value={info.confirmPassword}
                            onChange={(e) => {
                                setIsValidConfirmPassword(e.target.value===password)
                                setInfo({ ...info, confirmPassword: e.target.value })
                            }}
                            className={`block w-full mt-1 border-gray-300 rounded-md bg-red-100 h-8 focus:outline-none px-5 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50  border ${isValidConfirmPassword&&addressFormError?.password?false:true?"":" border-red-600"}   `}
                        />
                    </div>
                    {/* {(addressFormError?.password?.length==0)?<></>:<small className='text-red-600'>Passwords donot match </small>} */}
                </div>
                {/* <a href="#" className="text-xs text-red-600 hover:underline" > Forget Password? </a> */}
                <div className="flex items-center mt-4">
                    {/* {(isValidField&&isValidEmail&&isValidMobile&&isValidConfirmPassword&&(hasLowerCase&&hasUpperCase&&hasNumber&&hasSpecialChar&&hasMinimumLength)?
                    <><button
                    onClick={submitHandler} 
                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
                >Register</button></>
                    :
                    <><button disabled
                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                >Fill required fields to continue</button></>
                    )} */}
                    <button
                    onClick={submitHandler} 
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-700 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
                    >Register</button>
                </div>
                    <div className="mt-4 text-grey-600">
                        Already have an account?{' '}
                        <Link to="/login">
                            <span className="text-red-600 hover:underline">Log in</span>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Signup;