import React from 'react'
import Buyer from '../../assets/Logo/Buyer.png'

import SubscribeForUpdate from '../../pages/subscribeForUpdate/SubscribeForUpdate'
import Slider from '../../pages/silder/Slider'
const Testimonial = () => {
    


    return (
        <div className='my-10 w-full px-4 md:px-14 relative'>
            <div className='flex justify-center'>
                {/* <img src={Buyer} alt='teste' /> */}
                <h1 className='font-Lucida text-[2em] md:text-[3em] font-medium text-red-600 red-title-shadow'>Buyer's Testimonials</h1>
            </div>
            <div>
                {/* <img src={TestimonialImg} alt='' /> */}
                <Slider/>
            </div>
         <SubscribeForUpdate/>
        </div>
    )
}

export default Testimonial