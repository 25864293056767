import React from 'react'
import Layout from '../../components/layout/Layout'

const TermsAndConditions = () => {
  return (
    <Layout>
        <header className="bg-red-600 text-white p-4">
            <div className="container mx-auto">
                <h1 className="text-2xl font-semibold">Terms & Conditions</h1>
            </div>
        </header>

        <div className="container mx-auto my-8 p-8 bg-white rounded shadow-lg">
            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-4">Introduction</h2>
                <p className="pb-2 text-gray-600">The Website Owner, including subsidiaries and affiliates (“<a href="http://www.Mabesha.com">www.Mabesha.com</a>” or “Mabehsa” or “we” or “us” or “our”) provides the information contained on the website or any of the pages comprising the website (“website”) to visitors (“visitors”) (cumulatively referred to as “you” or “your” hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy and any other relevant terms and conditions, policies and notices which may be applicable to a specific section or module of the website.</p>
                <p className="pb-2 text-gray-600">Welcome to <a href="http://www.mabesha.com">www.mabesha.com</a>. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern <a href="http://www.mabesha.com">www.mabesha.com</a>'s relationship with you in relation to this website.</p>
                <p className="pb-2 text-gray-600">The term 'Mabesha' ‘<a href="http://www.mabesha.com">www.mabesha.com</a>’ or 'us' or 'we' refers to the owner of the website whose registered office is -</p>
                <p className="pb-2 text-gray-600">198 Rashbehari Avenue, Kolkata-700029, West Bengal.</p>
                <p className="pb-2 text-gray-600">The term 'you' refers to the user or viewer of our website.</p>
                <p className="pb-2 text-gray-600">The use of this website is subject to the following terms of use:</p>
                <p className="pb-2 text-gray-600">The content of the pages of this website is for your general information and use only. It is subject to change without notice</p>
                <p className="pb-2 text-gray-600">Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law</p>
                <p className="pb-2 text-gray-600">Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements</p>
                <p className="pb-2 text-gray-600">This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions</p>
                <p className="pb-2 text-gray-600">All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website</p>
                <p className="pb-2 text-gray-600">Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence</p>
                <p className="pb-2 text-gray-600">From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website. We have no responsibility for the content of the linked website</p>
                <p className="pb-2 text-gray-600">You may not create a link to this website from another website or document without Mabesha's prior written consent</p>
                <p className="pb-2 text-gray-600">Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority</p>
                
            </section>

        
            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-2">CANCELLATION & REFUND POLICY</h2>
                <b className="text-l font-semibold block mb-4">NOTE FOR RETURN & EXCHANGE :</b>
                <div className="pl-2">
                    <p className="pb-2 text-gray-600">* The items should be unused and unwashed for hygiene reasons.</p>
                    <p className="pb-2 text-gray-600">* The product should have the original packaging and tags in place. Items without the original tags will not be accepted.</p>
                    <p className="pb-2 text-gray-600">* Sarees with fall and edging would not be accepted for return or exchange. </p>
                    <p className="pb-2 text-gray-600">* Customized products cannot be returned or exchanged</p>
                    <p className="pb-2 text-gray-600">* Return/Exchange request that are not raised within 3 days of receiving the product would not be accepted.</p>
                    <p className="pb-2 text-gray-600">* Alterations would be done only once for the blouses or customized orders, if needed. If you need any further alterations, the alteration and courier charges would have to be borne by the customer.</p>
                    <p className="pb-2 text-gray-600">* Products bought during sale are non-refundable</p>
                </div>
            </section>
        
            <section className="mb-8">
                <b className="text-l font-semibold block mb-4">Cancellation Policy :</b>
                <p className="pb-2 text-gray-600">Incase there is an order cancellation, please do so before it is shipped. Shipping charges are non-refundable once the order is dispatched.</p>
                <p className="pb-2 text-gray-600">If  you cancel your order before it has been shipped, we will refund the entire amount</p>
                <p className="pb-2 text-gray-600">The shipping charges for sending the products back has to be borne by the customer</p>
                <p className="pb-2 text-gray-600">Once we have received the products a quality check would be done by our team</p>
                <p className="pb-2 text-gray-600">The amount refunded to you would be only for the products not the shipping charges</p>
            </section>

            <section className="mb-8">
                <b className="text-l font-semibold block mb-4">Exchange Policy: </b>
                <p className="pb-2 text-gray-600">Raise an exchange request within 3 days of receiving the product through . Once we have it with us, we will ship the replacement product to you within 5-7 working days. You can raise your exchange request through <a href="mailto:mabesha.2000@gmail.com">mabesha.2000@gmail.com</a>.  You will receive an approval mail from us within 48 hours if the request is accepted</p>
                <p className="pb-2 text-gray-600">A pick up would be arranged and a label would be sent to you via email which needs to be attached on the package for reverse pick up</p>
                <p className="pb-2 text-gray-600">Please make sure that the original packing is intact when the product is picked up.</p>
                <p className="pb-2 text-gray-600">No return and exchange request would be accepted if the return/exchange request is raised after 3 days of receiving the shipment.</p>
                <p className="pb-2 text-gray-600">You would be notified by us , once we receive your shipment.</p>
                <p className="pb-2 text-gray-600">Incase we do not have pick up service available at your location, you would have to self ship the product.</p>
                <p className="pb-2 text-gray-600">Exchange is not possible if the saree has fall and edging done to it.</p>
                <p className="pb-2 text-gray-600">Exchange would not be accepted if the product was customized for you.</p>
                <p className="pb-2 text-gray-600">Exchange would not be possible if the product is exclusive or single piece.</p>
                <p className="pb-2 text-gray-600">If you want to exchange the product you ordered for with another product of higher amount, we would give you a coupon code for the amount of the product you returned. You can use this code while placing order and pay the balance amount on your next purchase.</p>
                <p className="pb-2 text-gray-600">If you receive any defective product, do contact us immediately by mailing us at <a href="mailto:mabesha.2000@gmail.com">mabesha.2000@gmail.com</a> with your Order ID and send us a snapshot of the defect. We will either provide you a credit note or refund the amount of that defected product</p>
            </section>
        
            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-4">PRIVACY POLICY</h2>
                <p className="pb-2 text-gray-600">This privacy policy sets out how <a href="http://www.mabesha.com">www.mabesha.com</a> uses and protects any information that you give <a href="http://www.mabesha.com">www.mabesha.com</a> when you use this website</p>
                <p className="pb-2 text-gray-600"><a href="http://www.mabesha.com">www.mabesha.com</a> is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement</p>
                <p className="pb-2 text-gray-600"><a href="http://www.mabesha.com">www.mabesha.com</a> may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>
                <p className="pb-2 text-gray-600">We may collect the following information:</p>
                <p className="pb-2 text-gray-600">Name and Date of birth</p>
                <p className="pb-2 text-gray-600">Contact information including email address</p>
                <p className="pb-2 text-gray-600">Demographic information such as postcode, preferences and interests</p>
                <p className="pb-2 text-gray-600">Other information relevant to customer surveys and/or offers</p>
                <p className="pb-2 text-gray-600">We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
                <p className="pb-2 text-gray-600">Internal record keeping</p>
                <p className="pb-2 text-gray-600">We may use the information to improve our products and services</p>
                <p className="pb-2 text-gray-600">We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided</p>
                <p className="pb-2 text-gray-600">From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customize the website according to your interests.</p>
            </section>
        
            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-4">Security :</h2>
                <p className="pb-2 text-gray-600">We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
            </section>
        
            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-4">How we use cookies :</h2>
                <p className="pb-2 text-gray-600">A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site</p>
                <p className="pb-2 text-gray-600">Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences</p>
                <p className="pb-2 text-gray-600">We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system</p>
                <p className="pb-2 text-gray-600">Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us</p>
                <p className="pb-2 text-gray-600">You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website</p>
                <p className="pb-2 text-gray-600">You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to - Mabesha , 198, Rash Behari Avenue, Kolkata, West Bengal 700029</p>
                <p className="pb-2 text-gray-600">If you believe that any information we are holding on you is incorrect or incomplete, please write to us or email us as soon as possible. We will promptly correct any information found to be incorrect</p>
            </section>
        
            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-4">Contact Us :</h2>
                <p className="pb-2 text-gray-600">If there are any questions regarding this privacy policy you may contact us using the information below:</p>
            </section>
            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-4">Address :</h2>
                <p className="pb-2 text-gray-600">Mabesha , 198, Rash Behari Avenue, Kolkata, West Bengal 700029</p>
            </section>
            <section className="mb-8">
                <h2 className="text-xl font-semibold mb-4">Email Address:</h2>
                <p className="pb-2 text-gray-600"><a href="mailto:mabesha.2000@gmail.com">mabesha.2000@gmail.com</a></p>
            </section>

            {/* <section>
                
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="pb-2 text-gray-600"></p>
                <p className="text-gray-600">
                    By using our services, you agree to comply with and be bound by these terms and conditions.
                    If you do not agree to these terms, please do not use our services.
                </p>


            </section> */}

        </div>
    </Layout>
  )
}

export default TermsAndConditions
