import React from 'react'
import Layout from '../../components/layout/Layout'

const PrivacyPolicy = () => {
  return (
    <Layout>
     <body className="bg-gray-100 font-sans">


<header className="bg-red-600 text-white p-4">
    <div className="container mx-auto">
        <h1 className="text-2xl font-semibold">Privacy Policy</h1>
    </div>
</header>


<div className="container mx-auto my-8 p-8 bg-white rounded shadow-lg">


    <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Introduction</h2>
        <p className="text-gray-600">
            Welcome to our Privacy Policy page. Your privacy is important to us. This policy explains how we
            collect, use, and protect your personal information when you use our services.
        </p>
    </section>

   
    <section>
        <h2 className="text-xl font-semibold mb-4">1. Information Collection</h2>
        <p className="text-gray-600">
            We may collect personal information such as your name, email address, and other details when you
            use our services. We use this information to provide and improve our services.
        </p>

        <h2 className="text-xl font-semibold mt-6 mb-4">2. Cookies</h2>
        <p className="text-gray-600">
            We use cookies to enhance your experience on our website. You can choose to disable cookies in your
            browser settings if you prefer not to use them.
        </p>

    

    </section>

</div>

</body>
    </Layout>
  )
}

export default PrivacyPolicy
