import React, { useState, memo, useEffect } from 'react'
import { Link, useNavigate, useParams, } from 'react-router-dom';


import Layout from '../../components/layout/Layout'
import axios from 'axios'
import { apiBaseUrl } from '../../config/config'
import { getFromLocalStorage, removeAllFromLocalStorage, setToLocalStorage } from "../../helpers/helpers";
import { MdShoppingCartCheckout, MdEditLocationAlt } from "react-icons/md";
import { BsCaretRightFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { FaPowerOff, FaAddressBook, FaCartFlatbed } from "react-icons/fa6";
import { PiFolderSimpleUserBold } from "react-icons/pi";
import { ImAddressBook } from "react-icons/im"
import { MdAdminPanelSettings } from "react-icons/md"


import UserNav from './UserNav';
import UserInfo from './UserInfo';
import Shipping from './Shipping';
import PastOrderList from './PastOrderList';
import EditShipingAddress from './EditShipingAddress';
import EditUserProfile from './EditUserProfile';
import TestProdZoom from './TestProdZoom';

const ProfileMemorize = () => {
    const userRoutes = useParams();
    const [change, setChange] = useState(true)
    const [pageRoute, setPageRoute] = useState(userRoutes.profileRoutes)
    const [name, setName] = useState()
    const [lastName, setLastName] = useState()
    const [scrnWidth, setScrnWidth] = useState()
    const navigate = useNavigate()
        const [token, setToken] = useState(getFromLocalStorage('access_token'));

    const changeSaction = () => {
        setChange(false)
    }
    const profileSwitch = () => {
        setChange(true)
    }

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const getPastOrder = () => {
        axios.get(`${apiBaseUrl}api/orderhistory`, { headers })
            .then((res) => {
                // console.log("This is past order", res?.data)
                // setImgPath(res?.data?.img_path)
                // setPastorder(res?.data?.data)
            })
            .catch((err) => console.log("This error at thie time when fetch past order history",err))
    }
    useEffect(() => {
        getPastOrder()
    }, [])

    useEffect(() => {
        setPageRoute(userRoutes.profileRoutes);
        console.log("thisisroute", userRoutes.profileRoutes)
        setName(getFromLocalStorage("username"))
        setLastName(getFromLocalStorage("last_name"))
    }, [userRoutes])

    useEffect(() => {
        setScrnWidth(window.innerWidth)
    }, [])
    return (
        <Layout>
            {/* <div className='bg-[#F1F3F6] flex space-x-5 p-10'> */}
            {scrnWidth >= 768 ? '':
            <>
            <div className='bg-red-600 text-white flex gap-x-5  px-10 py-5'>
                <h1 className='text-[1.5rem] flex items-center'>{pageRoute?<>
                    {pageRoute=="orders"?<>
                    <FaCartFlatbed className='text-[1.2em] mr-2' /> Past Orders</>:<></>}
                    {pageRoute=="shippingadress"?<>
                    <FaAddressBook className='text-[1.2em] mr-2' /> Manage Address</>:<></>}
                    {pageRoute=="editaddress"?<>
                    <MdEditLocationAlt className='text-[1.2em] mr-2' /> Edit Address</>:<></>}
                </>:<><MdAdminPanelSettings className='text-[1.4em] mr-2' /> Profile Settings</>}</h1>
            </div>
            </>
            }
            <div className='bg-[#F1F3F6] flex gap-x-5 p-10'>
                {scrnWidth <= 768 ? 
                ''
                :
                <UserNav className="basis-[25%]" />
                }
                
                {pageRoute?
                <>
                    {pageRoute=="orders"?<>
                    <PastOrderList className={scrnWidth <= 768 ? 'basis-full':'basis-[70%]'}/></>:<></>}

                    {pageRoute=="shippingadress"?<>
                    <Shipping className={scrnWidth <= 768 ? 'basis-full':'basis-[70%]'}/></>:<></>}

                    {pageRoute=="editaddress"?<>
                    <EditShipingAddress className={scrnWidth <= 768 ? 'basis-full':'basis-[70%]'}/></>:<></>}

                    {pageRoute=="edit"?<>
                    <EditUserProfile className={scrnWidth <= 768 ? 'basis-full':'basis-[70%]'}/></>:<></>}

                    {pageRoute=="test"?<>
                    <TestProdZoom className={scrnWidth <= 768 ? 'basis-full':'basis-[70%]'}/></>:<></>}
                </>
                :
                <><UserInfo className={scrnWidth <= 768 ? 'basis-full':'basis-[70%]'}/></>}
                
                

            </div>
        </Layout>
    )
}
const Profile = memo(ProfileMemorize)
export default Profile
