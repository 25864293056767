import React, { memo, useEffect, useState } from "react";
import Layout from '../../components/layout/Layout'
import { apiBaseUrl } from "../../config/config";
import { Link, useNavigate, useParams, } from 'react-router-dom'
import axios from "axios";
const EventsView = () => {
  const events = useParams();
  const [event, setEventList] = useState();
  const [imageBase, setImageBase] = useState();
  const getEventlist = () => {
    axios
      .get(`${apiBaseUrl}api/events`)
      .then((res) => {
        setImageBase(res.data.img_path)
        var event=res.data.message.find(event=> event.id==events.id);
        setEventList(event);
        console.log("this is a new log")
        console.log(event)
      })
      .catch((err) =>
        console.log("this error come at the time of events api call", err)
      );
  };

  useEffect(() => {
    getEventlist();
  }, []);
  return (
   <Layout>
     <section className="relative pt-12 bg-blueGray-50">
    <div className="items-center flex flex-wrap mb-10">
      <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
        <img alt="..." className="max-w-full rounded-lg shadow-lg" src={imageBase+"/"+event?.image} />
      </div>
      <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
        <div className="md:pr-12">
          <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-red-600 mt-8">
            <i className="fas fa-rocket text-xl"></i>
          </div>
          <h3 className="text-3xl font-semibold">{event?.name}</h3>
          <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
          {event?.description}
          </p>
          <ul className="list-none mt-6">
            <li className="py-2">
              <div className="flex items-center">
                <div>
                  <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3"><i className="fas fa-fingerprint"></i></span>
                </div>
                <div>
                  <h4 className="text-blueGray-500">
                      {event?.details}
                  </h4>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    {/* <footer className="relative  pt-8 pb-6 mt-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-6/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              Made with <a href="https://www.creative-tim.com/product/notus-js" className="text-blueGray-500 hover:text-gray-800" target="_blank">Notus JS</a> by <a href="https://www.creative-tim.com" className="text-blueGray-500 hover:text-blueGray-800" target="_blank"> Creative Tim</a>.
            </div>
          </div>
        </div>
      </div>
    </footer> */}
    </section>
   </Layout> 
  )
}

export default EventsView
