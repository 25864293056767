import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentResponseHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCcaResponse = async () => {
      try {
        // Extract parameters from the query string
        const params = new URLSearchParams(location.search);
        const status = params.get('status');
        const orderId = params.get('orderId'); // Adjust parameter names based on CCAvenue response

        // Handle the response based on the status
        if (status === 'success') {
          console.log(`Payment successful for order ID: ${orderId}`);
          // Perform additional actions as needed
        } else if (status === 'failure') {
          console.log(`Payment failed for order ID: ${orderId}`);
          // Handle failure scenario
        } else {
          console.log('Unknown status');
          // Handle other statuses as needed
        }

        // Redirect the user to a thank you page or another appropriate page
        // navigate('/');
      } catch (error) {
        console.error('Error handling CCAvenue response:', error);
        // Handle error scenario
      }
    };

    // Call the function when the component mounts
    handleCcaResponse();
  }, [location.search, navigate]);

  return (
    <div>
      {/* Your component content */}
    </div>
  );
};




export default PaymentResponseHandler
