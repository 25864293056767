import React,{memo} from 'react'

const NoPageMemorize = () => {
  return (
    <div className='flex justify-center items-center h-screen w-full'>
      <h1 className="text-2xl text-red-600 font-bold">Sorr, Here Is Nothing</h1>
    </div>
  )
}
const NoPage = memo(NoPageMemorize)
export default NoPage