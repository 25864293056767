import axios from "axios";
import {
	apiBaseUrl, testBaseUrl
} from "../config/config";
import { getFromLocalStorage, removeAllFromLocalStorage } from "../helpers/helpers";

const allInstanceUrl = [
	{
		'base_url': apiBaseUrl,
		'instanceName': 'productList'
	},
	{
		'base_url': apiBaseUrl,
		'instanceName': 'categoryList'
	},
	{
		'base_url': apiBaseUrl,
		'instanceName': 'colorList'
	},
	{
		'base_url': apiBaseUrl,
		'instanceName': 'aboutUs'
	},
	{
		'base_url': apiBaseUrl,
		'instanceName': 'events'
	},
	{
		'base_url': apiBaseUrl,
		'instanceName': 'companyDetails'
	},
	{
		'base_url': apiBaseUrl,
		'instanceName': 'topBanner'
	},
	{
		'base_url': apiBaseUrl,
		'instanceName': 'bottomBanner'
	}
];

export let axiosInstances = {};

allInstanceUrl.map(item => {
	/*
	* create axios instance
	*/
	let instance = axios.create({
		baseURL: `${item.base_url}`,
		headers: {
			"Content-Type": "application/json",
			'Access-Control-Allow-Origin': '*'
		},
	});

	/*
	* handle request interceptor
	*/
	instance.interceptors.request.use(
		(config) => handleConfig(config),
		(error) => Promise.reject(error)
	);

	/*
	* handle response interceptor
	*/
	instance.interceptors.response.use(function (response) {
		return response;
	}, function (error) {
		if (error.response.status === 401) {
			logout();
		} else {
			return Promise.reject(error);
		}
	});

	/*
	* assign correspondance instance
	*/
	axiosInstances[item.instanceName] = instance;
})

/*
* logput on getting status 401 from any api
*/
const logout = () => {
	removeAllFromLocalStorage();
	window.location.replace('/');
}

/**
 * handle config for get/post
 */
const handleConfig = (config) => {
	const token = getFromLocalStorage("token");
	if (token) {
		config.headers["Authorization"] = `Bearer ${token}`;
	}
	if (
		config.url === "/exam/overAll" ||
		config.url === "/exam/overAllSubject" ||
		config.url === "learningLibrary/eLibraryCount" ||
		config.url === "/ticket/counts" ||
		config.url === "/blog/list" ||
		config.url === "/feedback/counts" ||
		config.url === "/school/appUsage"
	) {
		return config;
	}
	return config;
}
