import React, { useRef, useState } from 'react'

// import { useSpring, animated } from '@react-spring/web'
// import { useGesture } from '@use-gesture/core';
// import { useDrag } from '@use-gesture/react'
// import { useGesture } from '@use-gesture/react'
import { Link } from 'react-router-dom'
import Layout from '../../components/layout/Layout'
import { useLocation } from 'react-router-dom'
import { IoMdPaper } from "react-icons/io";
import { MdDone } from "react-icons/md";


import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


export default function TestProdZoom({className}) {
    const [previewIndex, setPreviewIndex] = useState(0); // Tracks the index of the preview image

    const handleImageClick = (index) => {
        setPreviewIndex(index); // Update preview index on click
    };
    const images = [
        { src: 'https://cdn.pixabay.com/photo/2024/05/12/09/28/brown-bear-8756478_1280.png', alt: 'Image 1' },
        { src: 'https://cdn.pixabay.com/photo/2023/02/08/18/36/tawny-owl-7777285_1280.jpg', alt: 'Image 2' },
        { src: 'https://cdn.pixabay.com/photo/2022/06/29/21/35/owl-7292587_960_720.jpg', alt: 'Image 3' },
      ];
      const productImage = {
        src: 'https://cdn.pixabay.com/photo/2024/05/12/09/28/brown-bear-8756478_1280.png',
        alt: 'Product Image',
      };
  return (
    <>
      <div className={className}>
          TestProdZoom
          <div className="">
              {/* <img src="https://cdn.pixabay.com/photo/2024/05/12/09/28/brown-bear-8756478_1280.png" alt='Hello World' /> */}
              {/* <ImageCropper {...productImage} /> */}
              {/* <div className="mb-3 max-h-[40rem]">
                  <TransformWrapper>
                      <TransformComponent>
                          <img className='max-h-[40rem]' src={images[previewIndex].src} alt={images[previewIndex].alt} />
                      </TransformComponent>
                  </TransformWrapper>
              </div>
              <div className="image-list flex">
                  {images.map((image, index) => (
                  <img
                      key={index} // Important for performance
                      src={image.src}
                      alt={image.alt}
                      onClick={() => handleImageClick(index)}
                      className={(previewIndex === index ? 'active' : '')+" h-auto w-full max-w-[33.333333%]"} // Add active class for styling (optional)
                  />
                  ))}
              </div> */}
          </div>
            <div className='h-screen flex justify-center items-center  bg-gray-200 '>
                <div className='bg-white md:w-[70%] pb-100 shadow-2xl rounded-md '>
                    <div className='bg-white h-60 w-60 rounded-full p-5  mx-auto -mt-20 relative'>
                        <div className='w-full h-full rounded-full bg-[#43B28A] p-5'>
                            <div className='w-full h-full rounded-full bg-[#0B6A4A] flex justify-center items-center'>
                                <IoMdPaper className='text-white text-[80px]' />
                            </div>
                            <div className=' bg-white h-20 w-20 rounded-full absolute bottom-5 right-2 p-1'>
                                <div className='w-full h-full rounded-full bg-[#106749] flex justify-center items-center'>
                                    <MdDone className='text-white text-[50px]' />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='w-full flex justify-center mt-6'>
                        <p className='font-bold text-black text-4xl mx-auto tracking-wider'>₹ 6000</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-black text-xl font-serif '>Order ID : #123456</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className='font-bold text-black text-3xl font-serif mt-7'>Payment Successful!</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-black text-xl font-serif mt-2'>The payment has been made successfully</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-black text-xl font-serif mt-2 '>Thanks you for being there with us</p>
                    </div>
                    <div className='w-full flex justify-center mt-5 mb-5'>
                        <Link to={'/productlist'}><button className='bg-[#0B6A4A] w-40 rounded h-10 text-white  text-lg'>Done</button></Link>
                    </div>
                </div>
            </div>
      </div>
    </>
  )
}


/* function ImageCropper({ src, alt }) {
    const [scale, setScale] = useState(1); // Initial zoom level

  const imageRef = useRef(null);

  const handleDrag = (event) => {
    const { dy } = event; // Get vertical drag movement

    // Calculate a new zoom factor based on drag distance:
    const newScale = Math.max(0.5, Math.min(2, 1 + dy / 100)); // Adjust sensitivity as needed

    setScale(newScale);
  };

  const gestures = useGesture({
    onDrag: handleDrag,
  });

  return (
    <div className="image-zoom" {...gestures}>
      <img
        ref={imageRef}
        src={src}
        alt={alt}
        style={{ transform: `scale(${scale})` }} // Apply zoom transform
      />
    </div>
  );
} */


