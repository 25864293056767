import React,{memo} from 'react'
import CollectionBanner from '../../assets/Banner/ExploreOurcollection2.jpg'
import { Link, useNavigate } from 'react-router-dom'

const OfferPageMemorize = () => {
  const navigateTo = useNavigate();
  return (
    <div className='h-auto w-full  bg-white relative '>
      <img className='w-full cursor-pointer' src={CollectionBanner} alt='this is bottom baneer' onClick={() => {navigateTo('/collections')}} /> 
      {/* <Link to='/productlist'><button className='text-white bg-red-600 h-16 w-60 bottom-36 right-72 rounded-full'>SHOP NOW</button></Link> */}
    </div>
  )
}
const OfferPage = memo(OfferPageMemorize)
export default OfferPage