import React, { memo, useEffect, useState } from 'react'

import Layout from '../../components/layout/Layout'
import './style.css'
import { trackOrder } from '../../api'
import { Link, useNavigate, useParams, } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { LuCalendar } from "react-icons/lu";
const TrackingOrder = () => {
    const order = useParams();
    const dispatch = useDispatch();
    const [track, setTrack] = useState(false);
    const [trackData, setTrackData] = useState();
    var status=["Your Order is: Pending","Your Order is: Confirm","Your Order is: In-Transit","Your Order was Cancelled","Your Order was Delivered "]
    useEffect(() => {
        dispatch(trackOrder(order.id))
        .then(res => {
            setTrackData(res.data);
        })
    }, []);
    useEffect(() => {
        if(trackData){
            console.log("thislogging", trackData);
        }
    }, [trackData]);

    return (
        <Layout>
            {
                (trackData) ?
                    <div className="p-4 max-w-md mx-auto pt-20 flow-root pb-10">
                        
                        <div  className='text-center text-2xl font-extrabold mb-2 text-green-900'  dangerouslySetInnerHTML={{__html: `<h2> ${status[trackData?.order_status]}</h2>`}} />
                        <ul role="list" className="-mb-8 trackList">
                            {trackData?.tracking?.scans?.map((trackItem, index)=>{
                                var months =["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November",  "December"]
                                var year=trackItem?.date.toString().split("T")[0].split("-")[0] 
                                var month=trackItem?.date.toString().split("T")[0].split("-")[1]
                                var day=trackItem?.date.toString().split("T")[0].split("-")[2]
                                var date= months[month-1]+" "+day+", "+year
                                
                                return(
                                    <>
                                        <li key={index}>
                                            <div className="relative pb-8">
                                                <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                                <div className="relative flex space-x-3">
                                                    <div>
                                                        <span className="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                                            <svg className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                        <div>
                                                            <p className="text-sm text-gray-500">{trackItem?.activity} at <a href="#" className="font-medium text-gray-900">{trackItem?.location}</a></p>
                                                        </div>
                                                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                                            <time datetime="2020-09-20">{date}</time>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </>
                                )
                            })}

                        </ul>
                    </div> :
                    <div className='flex justify-center items-center m-10'>
                        <div className='w-[60%] h-auto shadow-2xl p-5'>
                            <div className='flex justify-center space-x-5'>
                                <div className='flex space-x-3'>
                                    <label className='text-black text-sm font-serif'>Search By:</label>
                                </div>
                                <div className='flex space-x-3'>
                                    <input className='w-5 h-5 ' type='radio' name='SearchBy' />
                                    <label className='text-black text-sm font-serif'>Order ID/No</label>

                                </div>
                                <div className='flex space-x-3'>
                                    <input className='w-5 h-5 ' type='radio' name='SearchBy' />
                                    <label className='text-black text-sm font-serif'>Tracking ID/AWB</label>
                                </div>
                            </div>
                            <div className='flex justify-center mt-10'>
                                <input className='border border-[#D40E14] p-1 text-sm h-10 w-96 focus:outline-none rounded-tl-md rounded-bl-md' type='text' placeholder='Enter your Tracking ID/AWB' />
                                <button onClick={()=>setTrack(true)} className='text-sm text-white bg-[#D40E14] p-1 rounded-tr-md rounded-br-md w-32 hover:bg-red-500'>Track your order</button>
                            </div>
                            <p className='text-center font-serif  text-black mt-2'>Check current status of your shipment.</p>

                        </div>
                    </div>

            }




        </Layout>
    )
}

export default TrackingOrder