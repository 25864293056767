import React, { useState, useEffect, useRef } from 'react'
import Logo from '../../assets/Logo/footerLogo.png'
import InstgramLogo from '../../assets/Logo/instgramLogo.png'
import facebookLogo from '../../assets/Logo/facebook.png'
import { Link } from 'react-router-dom'
import silkIndiaLogo from '../../assets/Logo/smoi_india_logo.png'
import {
  setToLocalStorage,
  getFromLocalStorage,
  removeAllFromLocalStorage,
} from "../../helpers/helpers";
import { useDispatch } from 'react-redux';
import { companyDetails} from '../../api'


const Footer = () => {
  const dispatch = useDispatch();
  const [phone,setPhone] = useState();
  const [email,setEmail] = useState();
  const [address1,setAddress1] = useState();
  const [address2,setAddress2] = useState();
  const [facebook,setFacebook] = useState();
  const [instagram,setInstagram] = useState();
  const [whatsapp,setWhatsapp] = useState();

  useEffect(() => {
    dispatch(companyDetails())
    .then(response => {
      setPhone(response.message.phone)
      setEmail(response.message.email)
      setAddress1(response.message.address1)
      setAddress2(response.message.address2)
      setFacebook(response.message.facebook)
      setInstagram(response.message.instagram)
      setWhatsapp(response.message.whatsapp)
    })
    
  }, []);
  return (
    <>
      {/* <div className='bg-[#D40E14] h-auto flex justify-around text-white  py-10'> */}
      <footer className="bg-[#D40E14]">
        <div className="flex space-x-20 px-10">
          <div className='grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 text-white gap-4 py-10 px-10'>
            <div className='col-span-2 lg:col-span-1 lg:pr-5'>
              <img className=' mx-auto' src={Logo} alt='logo' />
              {/* <p className='text-[10px] text-center mt-3  mx-auto'>Iaculis urna id volutpat lacus laoreet non curabitur gravida arcu. Vivamus at augue eget arcu dictum varius duis. Enim ut sem viverra aliquet eget sit. In fermentum posuere urna nec tincidunt praesent semper feugiat.</p> */}
            </div>
            <div className='col-span-2 md:col-auto'>
              <h1 className=' text-xl font-serif'>Quick links</h1>
              <ul className='list-none text-xs my-3 '>
                <li className='my-3'><Link to={'/aboutus'}>About us</Link></li>
                <li className='my-3'><Link to={'/help_and_Support'}>Help & Support</Link></li>
                {/* <li className='my-3'><Link to={'/return_or_exchange_policy'}>Return or Exchange policy</Link></li> */}
                <li className='my-3'><Link to={'/terms_and_conditions'}>Terms & conditions</Link></li>
                {/* <li className='my-3'><Link to={'/privacy_policy'}>Privacy policy</Link></li> */}
              </ul>
            </div>
            <div className='col-span-2 md:col-auto' >
              <h1 className=' text-xl font-serif'>Get in touch</h1>
              <ul className='list-none text-xs my-3 '>
                <li className='my-3'>
                  <div className='flex'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-3 mt-2">
                      <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
                    </svg>
                    <h1 className="font-bold text-lg">Phone no:</h1>
                  </div>
                  <p className='text-xs my-1'>{phone}</p>
                </li>
                <li className='my-3'>
                  <div className='flex'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-3 mt-2">
                      <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                    </svg>
                    <h1 className="font-bold text-lg"> Address: Salt Lake</h1>
                  </div>
                  <p className='text-xs my-1'>{address1}</p>
                </li>
                <li className='my-3'>
                  <div className='flex'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-3 mt-2">
                      <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                    </svg>
                    <h1 className="font-bold text-lg"> Address: Gariahat</h1>
                  </div>
                  <p className='text-xs my-1'>
                    {address2}</p>
                </li>
              </ul>
            </div>
            <div className='col-span-2 md:col-auto'>
              <h1 className=' text-xl font-serif'>Find us on</h1>
              <Link to={instagram} target="_blank" >
                <div className='flex my-3'>
                  <img className='w-6 h-6 mr-3 ' src={InstgramLogo} alt='instgram' />
                  <p className='text-lg font-thin'>@mabesha.2000</p>
                </div>
              </Link>
              <Link to={facebook} target="_blank" >
                <div className='flex my-3'>
                  <img className='w-6 h-6 mr-3 ' src={facebookLogo} alt='instgram' />
                  <p className='text-lg font-thin'>Mabesha</p>
                </div>
              </Link>
              <div className='w-48 bg-red-200 flex flex-col justify-center items-center rounded'>
                <img className=' w-36 h-28 p-1' src={"https://www.holyweaves.com/cdn/shop/files/Silkmark_Icon_Logo_Transparent_ef11a4b1-400f-4c0c-9632-d67a32a1dff6.png?v=1686553601&width=400"} alt='silkIndiaLogo' />
             <p className='font-serif tracking-widest mt-2 text-center text-xl bg-black w-full uppercase'>Silk Mark</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer