import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { FaWhatsapp } from 'react-icons/fa6';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <section className="text-gray-600 body-font font-poppins">
    <Provider store={store}>
        <App />
    </Provider>
    <button className='bg-green-500 h-12 w-12 rounded-full text-28 flex justify-center items-center whatsapp-button' onClick={() => window.open('https://wa.link/3epapd')}><FaWhatsapp className='text-[35px] text-white' /></button>
  </section>
);

reportWebVitals();
