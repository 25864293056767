import React, { memo, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { subscribeEmail } from '../../api'
import Subscibe from '../../assets/Logo/Subscribe for updates.png'
const SubscribeForUpdateMemorize = () => {
  const dispatch = useDispatch();
  const [ userEmail, setUserEmail]=useState()
  const getSubscribe = () =>{
    console.log(userEmail)
    dispatch(subscribeEmail(userEmail))
    .then(res => {

    })
    .catch(() => {

    })
    setUserEmail('')
  }

  return (
    <div className='py-5'>
           <div className='px-3 flex justify-center bg-white'>
                {/* <img src={Subscibe} alt='sub' /> */}
                <h1 className='font-Lucida text-[2em] md:text-[3em] font-medium text-red-600 red-title-shadow'>Subscribe for update</h1>
            </div>
            <div className='text-center  flex justify-center bg-white'>
                <p className='max-w-[500px] mt-5 mb-10 text-sm  '>Stay up-to-date with our newsletter. Get updates on new arrivals and latest offers. Don’t worry, we won’t spam your inbox.</p>
            </div>
            <div className='flex justify-center mb-5 px-3 md:px-0 bg-white'>
                <input value={userEmail} onChange={(e)=>setUserEmail(e.target.value)} className='border border-[#D40E14] p-1 text-sm h-10 w-96 focus:outline-none rounded-tl-md rounded-bl-md' type='text' placeholder='Enter your email(E.g xyz@email.com)' />
                <button  onClick={getSubscribe} className='text-sm text-white bg-[#D40E14] p-1 rounded-tr-md rounded-br-md w-28 hover:bg-red-500'>Subscribe</button>
            </div>
           
    </div>
  )
}
const SubscribeForUpdate = memo(SubscribeForUpdateMemorize)
export default SubscribeForUpdate