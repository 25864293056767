
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'
import BottomToTopButton from '../bottomToTopButton/BottomToTopButton'
import { useEffect, useRef, useLocation } from 'react'
import {Helmet} from "react-helmet";
 
function Layout({ title, children, className }) {
  // const defTop = useRef(null)
  useEffect(()=>{
    const defTop = document.getElementById('root')
    if (defTop) {
      defTop.scrollIntoView({ behavior: "smooth" });
    }
  },[])
  return (
    <>
        <Helmet>
            <title>Mabesha</title>
            <meta name="description" content="Helmet application" />
        </Helmet>
      <div title={title}>
        <Navbar />
        <div className={`content ${className? className : ''}`}>
          {children}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Layout