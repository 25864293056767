import React, { Children, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, } from 'react-router-dom';

import { MdShoppingCartCheckout } from "react-icons/md";
import { BsCaretRightFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { FaPowerOff } from "react-icons/fa6";
import { LuClipboardEdit } from "react-icons/lu";
import { PiFolderSimpleUserBold } from "react-icons/pi";

import { getFromLocalStorage, removeAllFromLocalStorage, setToLocalStorage } from "../../helpers/helpers";
import { Tooltip } from '@mui/joy';

function UserNav({className}) {
  const [name, setName] = useState()
  const [lastName, setLastName] = useState()
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    // animFnctn()
    setIsLogin(getFromLocalStorage("isLogin"));
  });
    useEffect(() => {
        setName(getFromLocalStorage("username"))
        setLastName(getFromLocalStorage("last_name"))
    }, [])
    const logOut = () => {
        removeAllFromLocalStorage()
        setToLocalStorage("islogin", false)
        navigate("/login")
    }
  return (
    <>{/* w-[25%] */}
    <div className={` h-auto space-y-5 ${className}`}>
                    {/* <div className='w-full bg-white shadow-xl rounded-sm cursor-pointer' onClick={profileSwitch}> */}
                    <div className='w-full bg-white shadow-xl rounded-sm' >
                        <div className='p-5 flex' >
                            <img src='https://static-assets-web.flixcart.com/fk-p-linchpin-web/fk-cp-zion/img/profile-pic-male_4811a1.svg' alt='profilepick' />
                            <div className='ml-2'>
                                <p className='text-sm text-black pb-1'>Hello</p>
                                <h1 className="font-semibold  text-black">{name} {lastName}</h1>
                            </div>
                            <Tooltip title={`Edit ${name} profile`} variant="solid">
                                <Link className='ml-auto' to={'/profile/edit'}>
                                    <LuClipboardEdit variant="solid" />
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='w-full bg-white   shadow-xl rounded-sm   p-5'>
                        <Link to={ `${isLogin}` ? '/profile' : "/login"} className='font-extrabold text-lg text-[#878787] pb-5 block'>Dashboard</Link>
                        <Link to={ `${isLogin}` ? '/profile/orders' : "/login"}>
                            {/* <div className='flex justify-between cursor-pointer' onClick={changeSaction}> */}
                            <div className='flex justify-between cursor-pointer' >
                                <div className='flex space-x-5'>
                                    <MdShoppingCartCheckout className=' font-bold w-7 h-7 text-red-600' />
                                    <p className='font-extrabold text-lg text-[#878787]'>MY ORDERS</p>
                                </div>
                                <BsCaretRightFill className='w-7 h-7 text-red-600 ' />
                            </div>
                        </Link>
                        <p className='w-full h-[1px] bg-gray-300 my-7'></p>
                        <div className='flex justify-between'>
                            <div className='flex space-x-5'>
                                <FaUser className=' font-bold w-6 h-6 text-red-600' />
                                <p className='font-extrabold text-lg text-[#878787]'>ACCOUNT SETTINGS</p>
                            </div>

                        </div>
                        <div className='mt-7 text-left mx-14 space-y-5'>
                            <div className="text-sm hover:text-blue-700 cursor-pointer font-semibold"><Link to="/profile/shippingadress">Manage Addresses</Link></div>
                
                            {/* <div className="text-sm hover:text-blue-700 cursor-pointer font-semibold">Wishlist</div> */}
                        </div>
                        {/* <p className='w-full h-[1px] bg-gray-300 my-7'></p>
                        <div className='flex justify-between'>
                            <div className='flex space-x-5'>
                                <PiFolderSimpleUserBold className=' font-bold w-6 h-6 text-red-600' />
                                <p className='font-extrabold text-lg text-[#878787]'>MY STUFF</p>
                            </div>

                        </div> */}
                        <div className='mt-7 text-left mx-14 space-y-5'>
                            {/* <div className="text-sm hover:text-blue-700 cursor-pointer font-semibold">MY coupons</div>
                            <div className="text-sm hover:text-blue-700 cursor-pointer font-semibold">MY Reviews & Rating</div>
                            <div className="text-sm hover:text-blue-700 cursor-pointer font-semibold">All Notifications</div> */}
                        </div>
                        <div className='flex space-x-5 mt-7 cursor-pointer' onClick={logOut}>
                            <FaPowerOff className=' font-bold w-6 h-6 text-red-600' />
                            <p className='font-extrabold text-lg text-[#878787]'>Logout</p>
                        </div>


                    </div>


                </div>
    </>
  )
}

export default UserNav