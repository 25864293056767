import React, { useState, useEffect, useRef } from 'react'

import { Link, useNavigate } from 'react-router-dom';

import { FaThList, FaTh, FaSearchPlus, FaRegHeart, FaHeart, FaStar, FaPlus, FaTimes, FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { FaCheck } from "react-icons/fa6"
import { FaCircleDot } from "react-icons/fa6";
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import { getFromLocalStorage, setToLocalStorage } from "../../helpers/helpers";
import { generateOtp, verifyOtp, addShipping, updateUser } from '../../api'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from "react-redux";
import { Alert, Autocomplete } from '@mui/material'



function GuestRegistration() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [emailCheckout, setEmailCheckout] = useState();
  const emailRef = useRef();
  const [ formpay, setFormpay ] = useState(false)
  
  const [is_registered,setIsRegistered] = useState(false);
  const [otpSent,setOtpSend] = useState(false);
  const [otpFilled,setOtpFilled] = useState(false);
  const [finalemail, setFinalemail] = useState();
  const [billadd1,setBilladd1] = useState();
  const [phone,setPhone] = useState("9000090000");
  const [billcity,setBillcity] = useState();
  const [pincode,setPinCode] = useState();
  const [landmark,setLandmark] = useState();
  const [shipadd1,setShipadd1] = useState();
  const [shipadd2,setShipadd2] = useState();
  const [shipcity,setShipcity] = useState();
  const [state, setState] = useState();
  const [customer,setCustomer] = useState("Guest");
  const [deliveryguide,setDeliveryguide] = useState();
  const[couponval,setCouponval] = useState();
  //const[clientsecret,setClientsecret] = useState();
  const [clientSecret, setClientSecret] = useState("");
  //const[taxnval,setTaxnval] = useState();
  let [disable, setDisable] = useState(false);
  const [disableemail, setDisableEmail] = useState(true);
//   let [billAdd, setBillAdd] = useState(false);
  let [billAdd, setBillAdd] = React.useState({
    // gilad: true,
    chackState: true,
    // antoine: true,
  });
  let [checkout, setCheckout] = useState(false);

  const [otp, setOtp] = useState(["","","","","",""]);

  useEffect(() => {
    setOtpFilled(otp?.join("").length === 6);
  }, [otp]);

  useEffect(() => {
    if(otpSent){
      if(getFromLocalStorage('userId')){
        if(customer||phone){
          var req = {
            "name" : customer,
            "last_name" : "",
            "email" : emailCheckout,
            "phone" : "91"+phone,
          }
          dispatch(updateUser(getFromLocalStorage('userId'), req))
          .then(response => {

          })
        }
      }
  }
    
  }, [customer, phone]);


  let states = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"
  ]


  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    width: 70,
    height: 40,
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          right: '10px',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#8b2845',
          },
        },
        '&:not(.Mui-checked)': {
          filter: 'drop-shadow(0 0 1px #000)',
        },
    },
    '& .MuiSwitch-track': {
    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#8b2845',
    borderRadius: 22 / 1,
    width: '100%',
    '&:before, &:after': {
        //content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: '11px',
        fontWeight: 'bold',
        width: 25,
        height: 16,
    },
    '&:before': {
        content: '"ON"',
        color: '#fff',
        textAlign: 'left',
        left: 13,
    },
    '&:after': {
        content: '"OFF"',
        color: '#fff',
        textAlign: 'right',
        right: 11,
    },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#001e3c' : '#ffffff',
        boxShadow: 'none',
        //opacity: 0,
        width: 22,
        height: 22,
        margin: 0,
        marginLeft:0,
    },
  }));

  function showshipping(ev) {
    // setBillAdd(!billAdd)
    // console.log('check Billing', billAdd)
    setBillAdd({
        ...billAdd,
        [ev.target.name]: ev.target.checked
    })
  }

//   var emailval = '';
  function checkBlank(){
    // console.log('check email', emailCheckout)
    if(!otpSent){
        var submit = {
            email: emailCheckout
        }
        dispatch(generateOtp(submit))
        .then(res => {
            setIsRegistered(res?.is_registered)
          setOtpSend(true)
        })
    }else{
        if(emailCheckout) {
            var submit = {
                email: emailCheckout,
                otp: otp.join("")
            }
            dispatch(verifyOtp(submit))
            .then(response => {
                console.log("verifyotp",response);
                setToLocalStorage("username", response?.user?.name)
                setToLocalStorage("last_name", response?.user?.last_name)
                setToLocalStorage("useremail", response?.user?.email)
                setToLocalStorage("phone", response?.user?.phone)
                setToLocalStorage("gender", response?.user?.gender)
                setToLocalStorage("access_token", response?.access_token)
                setToLocalStorage("isLogin",true)
                setToLocalStorage("userId",response?.user?.id)
                if(is_registered) {  
                    window.location.reload();
                }
                setDisable(true)
                setDisableEmail(false)
            })
        } else {
            emailRef.current.focus()
        }
    }
  }

  function handleChange(){
    const payload = {
        name: customer,
        address: billadd1,
        landmark: landmark,
        state: state,
        city: billcity,
        zip: pincode,
        phone: phone?"+91"+phone:'',
        email: emailCheckout,
        default_shipping: 1
    }
    
    dispatch(addShipping(payload)).then(response => {
        window.location.reload();
    })
  }
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);

  function handleOtpChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if(value && index < otp?.length-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  function handleOtpBackspaceAndEnter(e, index) {
    if(e.key === "Backspace" && !e.target.value && index > 0){
      otpBoxReference.current[index - 1].focus()
    }
    if(e.key === "Enter" && e.target.value && index < otp?.length-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  function handleEmail(e) {
    if(e.key === "Enter" && e.target.value ){
      // Email is entered
      var email = e.target.value
      var submit = {
        "email": email
      }
    }
  }

  return (
    <>
    <div className="col-12 rounded-lg">
        <div className="card bg-white shadow-md rounded-lg mb-6">
            <div className="card-body py-5">
                <h5 className='text-left mb-2 font-bold flex items-center'>{disableemail? <FaCircleDot className='text-[#ffcdd2] mr-2'  />:<FaCheck className='text-[#21d121] mr-2' />} Contact information</h5>
                {disableemail?
                    <>
                        <p>Please provide an email address to receive your order confirmation email.</p>
                        <div className="mb-3">
                            <label htmlFor="uEmail" className="form-label">Email address</label>
                            <input 
                            type="email" 
                            ref={emailRef} 
                            className="form-control block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                            id="uEmail" 
                            name="uEmail" 
                            defaultValue={emailCheckout} 
                            placeholder="name@example.com" 
                            onKeyUp={(e)=> handleEmail(e)}
                            onChange={(ev)=>{ setEmailCheckout(ev.target.value) }} />
                        </div>
                        {otpSent?
                        <>
                            <div className="mb">
                            <label htmlFor="uOTP" className="form-label"> {is_registered?"Welcome Back, ":"Let's Board You, "} Set OTP</label>
                            <div className="grid grid-cols-6 gap-2">
                                {otp.map((item, index)=>{
                                    return(
                                        <input type="number" 
                                        className="form-control no-spinner  rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
                                        id={"uOTP" +index}
                                        name={"uOTP" +index}     
                                        key={index} 
                                        value={item} 
                                        maxlength="1"  
                                        onChange={(e)=> handleOtpChange(e.target.value, index)}
                                        onKeyUp={(e)=> handleOtpBackspaceAndEnter(e, index)}
                                        ref={(reference) => (otpBoxReference.current[index] = reference)}
                                         />
                                    )
                                })}
                                
                            </div>
                        </div>
                        <small className="text-muted d-block mt-3 mb-4">* By signing up you confirm that you have read the <Link className='text-indigo-600' target="_blank" to="/privacy-policy/">Privacy Policy</Link> and agree that your email and the provided information will be collected and used by Mabesha for the purposes of sending news, promotions and updates via email.</small>
                        
                        </>:<></>
                        }
                        <div className="d-flex justify-content-end">
                            <Button onClick={checkBlank} className='btn btn-outline-default' variant="outlined">Continue</Button>
                        </div>
                    </>:''
                }
            </div>
            
        </div>
    </div>
    <div className="col-12 rounded-lg">
        <div className="card bg-white shadow-md rounded-lg mb-6">
            <div className="card-body py-5">
                <h5 className='text-left mb-2 font-bold flex items-center'>{disable? <FaCircleDot className='text-[#ffcdd2] mr-2'  />:<FaCheck className='text-[#21d121] mr-2' />} Shipping & Billing</h5>
                { disable? 
                <>
                    <h6 className="sub-title-block mt-5">DELIVERY ADDRESS</h6>

                    <div className="row g-3">
                        <div className="col-12">
                            <label htmlFor="inputAddress" className="form-label">Customer Name</label>
                            <input onChange={(ev) => setCustomer(ev.target.value)} type="text" className="form-control block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" id="billing_customer_name" defaultValue={customer} name="billing_customer_name"  placeholder="" />
                        </div>
                        <div className="col-12">
                            <label htmlFor="inputAddress" className="form-label"> Street Address</label>
                            <input onChange={(ev) => setBilladd1(ev.target.value)} type="text" className="form-control block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" id="billi_address1" name="billi_address1"  placeholder="1234 Main St" />
                        </div>
                        <div className="col-12">
                            <label htmlFor="inputAddress2" className="form-label">Phone</label>
                            <input onChange={(ev) => setPhone(ev.target.value.slice(0,10))} type="text" className="form-control block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" id="billi_address2" name="billi_address2" placeholder="Apartment, studio, or floor" />
                        </div>
                        <div className="col-12">
                            <label htmlFor="state" className="form-label">State {state}</label>
                            <Autocomplete
                                sx={(theme) => ({
                                display: 'inline-block',
                                width: '100%',
                                    '& input': {
                                        width: '100%',
                                        paddingTop: '0.375rem',
                                        paddingBottom: '0.375rem',
                                        paddingLeft: '0.625rem',
                                        paddingRight: '0.625rem',
                                        border: '1px solid #d1d5db',
                                        borderRadius: '0.375rem',
                                    //   bgcolor: 'background.paper',
                                    //   color: theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                })}
                                onInputChange={(ev, newInput) => setState(newInput)}
                                id="state"
                                options={states}
                                renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <input type="text"   {...params.inputProps} />
                                </div>
                                )}
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputCity" className="form-label">City</label>
                            <input onChange={(ev) => setBillcity(ev.target.value)} type="text" className="form-control block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" id="bill_city" name="bill_city"  />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="inputCity" className="form-label">Zip/Pin Code</label>
                            <input onChange={(ev) => setPinCode(ev.target.value)} type="text" className="form-control block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" id="bill_city" name="bill_city"  />
                        </div>
                        <div className="col-12">
                            <label htmlFor="inputAddress" className="form-label">Landmark</label>
                            <input onChange={(ev) => setLandmark(ev.target.value)} type="text" className="form-control block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" id="ship_address1" name="ship_address1" placeholder="1234 Main St" />
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Button className='btn btn-outline-default' onClick={handleChange}>Continue</Button>
                    </div>
                </>
                :
                ''
                }
            </div>
        </div>
    </div>

    </>
  )
}

export default GuestRegistration