import React, { memo, useState, useEffect, useRef } from "react";
import { Link,useParams, useSearchParams,Navigate ,useNavigate,useLocation} from 'react-router-dom'



import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Swiper, SwiperOptions, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Scrollbar } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



export default function ZoomPop({imgItem, imgBGPath, imgPath, imgAlt}) {
    const [count, setCount] = useState(0);
    const zoomSlideRef = useRef(null);
    // console.log('Hello SD prop', imgItem)
    return (
        <>
            <div className="subCont-2 flex-grow flex-shrink-0 flex-basis-full z-[9]" style={{maxHeight: 'calc(100vh - 12rem)'}}>
                <div className="prodMagnific z-[9] md:mb-[20px] h-full overflow-hidden">
                    <TransformWrapper>
                        <TransformComponent>
                            <img className='zoomed' src={`${imgBGPath}/${imgItem[count]}?sharpen=true`} alt={imgAlt}  />
                        </TransformComponent>
                    </TransformWrapper>
                </div>
            </div>
            <div className="subCont-1" style={{ minWidth: '100%', width: '-webkit-fill-available' }}>
                <Swiper
                    // loop={true}
                    modules={[Pagination, Navigation, Scrollbar]}
                    // spaceBetween={10}
                    slidesPerView={3}
                    navigation={false}
                    scrollbar={{
                        hide: false,
                    }}
                    // direction={'horizontal'}
                    // centeredSlides={true}
                    // autoplay={{
                    //     delay: 2500,
                    //     disableOnInteraction: false,
                    // }}
                    // navigation
                    // pagination={{ clickable: true, }}
                    // modules={[Autoplay, Pagination, Navigation]}
                    // className="md:h-[18em] h-auto"
                    ref={zoomSlideRef}
                    className="zoom-mob"
                >

                    {
                        imgItem?.map((item, index) => {
                            // console.log('=========>>>>>>========')
                            // console.log(imgUrlThumb, item)
                            // console.log('==========<<<<<<====')
                            return (
                                <SwiperSlide key={index} className={`${count === index ? 'activated':''}`}>
                                    {/* {index} */}
                                    <div className="flex flex-wrap justify-center items-center h-full">
                                        <img
                                            onClick={() => { 
                                                setCount(index);
                                             }}
                                            className={`cursor-pointer h-20 w-16 rounded-md`}
                                            // src={`https://mabesha.com/admin/storage/product/product_logo/product_image/${item}`}
                                            src={`${imgPath}/${item}?sharpen=true?quality=100`}
                                            // alt={`${productName}-${index}`}
                                            // src=""
                                            alt=""
                                            style={{ objectFit: 'cover', objectPosition: 'top' }}
                                        />
                                    </div>
                                </SwiperSlide>
                            );
                        })
                    }
                </Swiper>
                {/* {(imgItem.length > 3) ? <span className="underline text-[.8em] font-bold mt-2 block text-center">+{imgItem.length - 3} more 1</span> : ''} */}
            </div>
        </>
    )
}