import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getFromLocalStorage } from "../../helpers/helpers";
import logo from '../../assets/Logo/Logo.png'
import './inv.css'
import PastOrderList from '../../pages/profile/PastOrderList';



const Invoice = () => {
    const [email, setEmail] = useState()
    const [name, setName] = useState()
    const [lastName, setLastName]=useState()
    const [phoneNo, setPhoneNo]= useState()
    const [gender, setGender]= useState(null)
    const [pastOrderItem, setPastOrderItem]= useState()
    const [paymentDate, setPaymentDate]= useState()
    const [currentYear, setCurrentYear]= useState()
    useEffect(() => {
        const userEmail = getFromLocalStorage("useremail");
        const userName = getFromLocalStorage("username");
        const LastName = getFromLocalStorage("last_name");
        const phone = getFromLocalStorage("phone");
        const Gender = getFromLocalStorage("gender");
        setPastOrderItem(JSON.parse(getFromLocalStorage("pastOrderItem")) || '')
        var months =["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November",  "December"]
        var year=JSON.parse(getFromLocalStorage("pastOrderItem"))?.created_at.toString().split("T")[0].split("-")[0] 
        var month=JSON.parse(getFromLocalStorage("pastOrderItem"))?.created_at.toString().split("T")[0].split("-")[1]
        var day=JSON.parse(getFromLocalStorage("pastOrderItem"))?.created_at.toString().split("T")[0].split("-")[2]
        setPaymentDate(months[month-1]+" "+day+", "+year)
        setCurrentYear(parseInt(year.substring(2)))
        setEmail(userEmail || '');
        setName(userName || '');
        setLastName(LastName || " ")
        setPhoneNo(phone || "")
        setGender(Gender || null)
    }, []);
    console.log('Order Item', pastOrderItem)
    return (
        <>
            <button className='btnPrint m-2' onClick={()=>{window.print()}}>Print this page</button>
            <div className="w-full px-5">
                <div className="table-responsive">
                    <table className='w-full' cellspacing="0">
                        <tbody>
                            <tr>
                                <td colSpan="7">
                                    <div className="flex items-center">
                                        <img className=' w-28 m-2' src={logo} alt='This is compny logo' />
                                        <div className='ml-[3rem]'>
                                            <p className='text-[12pt] font-bold'>MABESHA</p>
                                            <p>Email - mabesha2000@gmail.com</p>
                                            <p>Phone - +91 86976 59729</p>
                                            <p>GSTN No. - 19AJBPP5850F1Z3</p>
                                            <p>198,Rash Behari Avenue, Kol-29</p>
                                        </div>
                                    </div>
                                </td>
                                <td className='text-right' colSpan="3">
                                    <p class="text-[22pt] ">Invoice_{pastOrderItem?.payment_id}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className='p-2 w-[50%] border-e-[1px] border-t-[1px]' colSpan="5">
                                    <table className="w-full">
                                        <tr>
                                            <td>Order Id: #</td>
                                            <td>{pastOrderItem?.order_id}</td>
                                        </tr>
                                        <tr>
                                            <td>Date</td>
                                            <td>{paymentDate}</td>
                                        </tr>
                                    </table>
                                </td>
                                <td className='p-2 border-t-[1px]' colSpan="5">
                                    <table className="w-full">
                                        <tr>
                                            <td>Place Of Supply</td>
                                            <td>West Bengal (19)</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td className='p-2 w-[50%] border-e-[1px] border-t-[1px]' colSpan="5">
                                    <table className="w-full">
                                        <thead>
                                            <th>
                                                <td>Bill From</td>
                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>MABESHA</p>
                                                    <p>Email - mabesha2000@gmail.com</p>
                                                    <p>Phone - +91 86976 59729</p>
                                                    <p>GSTN No. - 19AJBPP5850F1Z3</p>
                                                    <p>198,Rash Behari Avenue, Kol-29</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td className='p-2 border-t-[1px]' colSpan="5">
                    
                                    <table className="w-full">
                                        <thead>
                                            <th>
                                                <td>Ship To</td>
                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <p>{name} {lastName}</p>
                                                    <p>EMAIL - {email}</p>
                                                    <p>MOBILE - {phoneNo?.toString().substring(2, 12)}</p>
                                                    <p>{pastOrderItem?.address}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table className='w-full'>
                        <thead>
                            <tr className='bg-[#F2F2F3]'>
                                <th className='p-2 border-e-[1px] border-t-[1px]' rowSpan="2" width="3%">#</th>
                                <th className='p-2 border-e-[1px] border-t-[1px]' rowSpan="2">Item &amp; Description</th>
                                <th className='p-2 border-e-[1px] border-t-[1px]' rowSpan="2">HSN/SAC</th>
                                <th className='p-2 border-e-[1px] border-t-[1px]' rowSpan="2">Qty</th>
                                <th className='p-2 border-e-[1px] border-t-[1px]' rowSpan="2">Rate</th>
                                {pastOrderItem?.gst<=0?
                                <>
                                    <th className='p-2 border-e-[1px] border-t-[1px]' colSpan="2">CGST</th>
                                    <th className='p-2 border-e-[1px] border-t-[1px]' colSpan="2">SGST</th>
                                </>
                                :
                                <>
                                    <th className='p-2 border-e-[1px] border-t-[1px]' colSpan="2">IGST</th>
                                </>
                                }
                                <th className='p-2 border-e-[1px] border-t-[1px]' rowSpan="2">Amount</th>
                            </tr>
                            <tr className='bg-[#F2F2F3]'>
                            {pastOrderItem?.gst<=0?
                                <>
                                    <th className='p-2 border-e-[1px] border-t-[1px]'>%</th>
                                    <th className='p-2 border-e-[1px] border-t-[1px]'>Amt</th>
                                    <th className='p-2 border-e-[1px] border-t-[1px]'>%</th>
                                    <th className='p-2 border-e-[1px] border-t-[1px]'>Amt</th>
                                </>
                                :
                                <>
                                    <th className='p-2 border-e-[1px] border-t-[1px]'>%</th>
                                    <th className='p-2 border-e-[1px] border-t-[1px]'>Amt</th>
                                </>
                            }
                            </tr>
                        </thead>
                        <tbody>
                            {pastOrderItem?.products?.map((product, index)=>{
                                return(
                                    <>
                                    <tr className='border-b-[1px]'>
                                        <td className='p-2 border-e-[1px] border-t-[1px]'>{index + 1}</td>
                                        <td className='p-2 border-e-[1px] border-t-[1px]'>
                                            <h3 className=''>{product?.name}</h3>
                                            {product?.color ? <p className='text-[.7em]'><b>Color:</b> {product?.color}</p>:'' }
                                        </td>
                                        <td className='p-2 border-e-[1px] border-t-[1px]'>{product?.product_hsn}</td>
                                        <td className='p-2 border-e-[1px] border-t-[1px]'>{product?.quantity}</td>
                                        <td className='p-2 border-e-[1px] border-t-[1px]'>{((product?.price)-((product?.price*5)/105)).toFixed(2)}</td>
                                        {pastOrderItem?.gst<=0?
                                        <>
                                            <td className='p-2 border-e-[1px] border-t-[1px]'>2.5</td>
                                            <td className='p-2 border-e-[1px] border-t-[1px]'>{((product?.price*2.5)/105).toFixed(2)}</td>
                                            <td className='p-2 border-e-[1px] border-t-[1px]'>2.5</td>
                                            <td className='p-2 border-e-[1px] border-t-[1px]'>{((product?.price*2.5)/105).toFixed(2)}</td>
                                        </>
                                        :
                                        <>
                                            <td className='p-2 border-e-[1px] border-t-[1px]'>5</td>
                                            <td className='p-2 border-e-[1px] border-t-[1px]'>{((product?.price*5)/105).toFixed(2)}</td>
                                        </>
                                        }
                                        <td className='text-right p-2 border-e-[1px] border-t-[1px]'>₹ {product?.price}/-</td>
                                    </tr>
                                    </>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={pastOrderItem?.gst<=0? "7" : "5"}>
                                    <p><b>Place of despatch:</b> 198,Rash Behari Avenue, Kol-29</p>
                                </td>
                                <td colSpan={3} className='text-right'>
                                    <p className='flex justify-between items-center'><b>Sub Total:</b> {pastOrderItem?.subtotal}</p>
                                    {pastOrderItem?.discount ? <p className='flex justify-between items-center'><b>Discount <small>({pastOrderItem?.discount_code.toUpperCase()})</small>:</b> {pastOrderItem?.discount}</p> : ''}
                                    
                                    <p className='flex justify-between items-center'><b>Delivery Charge:</b> 
                                        {(pastOrderItem?.free_shipping==="1")? 
                                        <><span className="line-through ml-auto">₹ {pastOrderItem?.delivery_charge}</span> &nbsp; <span className='text-sm text-black-600'>Free</span></>
                                        :
                                        <>₹ {pastOrderItem?.delivery_charge}</>}
                                    </p>
                                    {pastOrderItem?.gst>0?
                                    <><p className='flex justify-between items-center'><b>IGST<small>(5.00%)</small>:</b> {pastOrderItem?.gst}</p></>
                                    :
                                    <><p className='flex justify-between items-center'><b>CGST<small>(2.5%)</small>:</b> {pastOrderItem?.cgst}</p>
                                    <p className='flex justify-between items-center'><b>SGST<small>(2.5%)</small>:</b> {pastOrderItem?.sgst}</p></>}
                    
                                </td>
                            </tr>
                            <tr>
                                {pastOrderItem?.gst<=0? (<td colSpan={2}></td>):''}
                                <td colSpan={5}></td>
                                <td colSpan={3} className="text-right border-t-[5px] border-double border-[#0000001a]">
                                    <p><b>Grand Total:</b> {(pastOrderItem?.free_shipping==="1")?(pastOrderItem?.total_amount- pastOrderItem?.delivery_charge) :pastOrderItem?.total_amount}</p>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div className="bg-gray-200 w-full px-5">
                {/*                 
                <div className="env-header flex justify-between items-center py-5">
                    <div>
                        <h1 className='text-[2rem]'>Tax Invoice</h1>
                        <small>Mab/{currentYear}-{currentYear+1}/{pastOrderItem?.order_id}</small>
                    </div>
                    <img className=' w-28 m-2' src={logo} alt='This is compny logo' />
                </div>
                <table className="payIssued w-full bg-white">
                    <tbody>
                        <tr>
                            <td className="payIssued-item w-4/12">
                                <b className='block text-sm'>Payment Date</b>
                                <span className='block text-gray-400 mb-2'>{paymentDate}</span>

                            </td>
                            <td className="payIssued-item w-4/12">
                                <h5 className='block text-sm font-bold mb-2'>From</h5>
                                <p>MABESHA</p>
                                <p>Email - mabesha2000@gmail.com</p>
                                <p>Phone - +91 86976 59729</p>
                                <p>GSTN No. - 19AJBPP5850F1Z3</p>
                                <p>198,Rash Behari Avenue, Kol-29</p>
                            </td>
                            <td className="payIssued-item w-4/12">
                                <h5 className='block text-sm font-bold mb-2'>Bill to</h5>
                                <p>{name} {lastName}</p>
                                <p>EMAIL - {email}</p>
                                <p>MOBILE - {phoneNo?.toString().substring(2, 12)}</p>
                                <p>{pastOrderItem?.address}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="payForItem w-full bg-white pt-5">
                    <thead>
                        <tr>
                            <th className= "text-left">Order ID:   #{pastOrderItem?.order_id} </th>
                            <th>AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pastOrderItem?.products?.map((product, index)=>{
                            return(
                                <>
                                <tr>
                                    <td>
                                        <h3 className=''>{product?.name}</h3>
                                        <p className='text-[.7em]'><b>Color:</b> {product?.color}</p>
                                        <p className='text-[.7em]'><b>HSN Code:</b> {product?.product_hsn}</p>
                                        <p className='text-[.7em]'><b>Quantity:</b> {product?.quantity}</p>
                                    </td>
                                    <td className='text-right'>₹ {product?.price}/-</td>
                                </tr>
                                </>
                            )
                        })}

                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <p><b>Place of supply:</b> 198,Rash Behari Avenue, Kol-29</p>
                            </td>
                            <td colSpan={2} className='text-right'>
                                <p><b>Sub Total:</b> {pastOrderItem?.subtotal}</p>
                                <p><b>Delivery Total:</b> {pastOrderItem?.delivery_charge}</p>
                                {pastOrderItem?.gst>0?
                                <><p><b>GST(5.00%)</b> {pastOrderItem?.gst}</p></>:<><p><b>CGST(2.5%)</b> {pastOrderItem?.cgst}</p>
                                <p><b>SGST(2.5%)</b> {pastOrderItem?.sgst}</p></>}
                                
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colSpan={2} className="text-right border-t-[5px] border-double border-[#0000001a]">
                                <p><b>Grand Total:</b> {pastOrderItem?.total_amount}</p>
                            </td>
                        </tr>
                    </tfoot>
                </table> */}

                <div className="env-footer py-5">
                    <ul className='text-[.8em]'>
                        <li>** NO GUARANTEE FOR COLOUR AND ZARI.</li>
                        <li>** RECOMMENDED DRY WASH</li>
                        <li>** GOODS CAN BE EXCHANGEED ONLY ONCE WITHIN 7 DAYS OF DELEVERD</li>
                    </ul>
                </div>
                {pastOrderItem?.order_status==2?<><div className="watermark">Cancelled</div></>:""}
            </div>

        </>
    )
}

export default Invoice
