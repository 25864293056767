import React, { memo, useRef} from 'react'


import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import 'swiper/css/pagination';


import Fram from '../../assets/Logo/Group 37.png'
import { FaQuoteLeft } from "react-icons/fa";


function SliderMemorize() {
 
  const homeTestimo = useRef();
  // const swiperSlide = useSwiperSlide();

  const testimonialItem = [
    {
      id: '1',
      source: Fram,
      TestiDtl: "Huge collection of authentic sarees and dress materials. Easily accessible with bustling crowds and shoppers. Reasonable price guaranteed quality materials",
    },
    {
      id: '2',
      source: Fram,
      TestiDtl: "Excellent place for saree lovers. They have a wide variety at different price range. Aesthetic and affordable. They also sell men's shirts made of pure cotton",
    },
    {
      id: '3',
      source: Fram,
      TestiDtl: "Lovely collection of traditional saris like linen, cottons, madhubani prints. And very helpful staff. Good prices",
    },
    {
      id: '4',
      source: Fram,
      TestiDtl: "Awesome collection, so tempting that you can't come out without making a purchase.",
    },
  ];

  const handlenext = () => {
      homeTestimo.current.swiper.slideNext();
  };
  const handleprev = () => {
      homeTestimo.current.swiper.slidePrev();
  };

  return (
    <div className='my-10'>
      <div className='max-w-[1400px] mx-auto space-x-10 relative'>
      <div className="lg:w-[50%] md:w-[60%] mx-auto relative">
        <img className='' src={Fram} alt='fram' />
        <div className="absolute top-0 left-0 w-full h-full py-[2.6rem] md:py-[6.9rem] sm:py-[6rem] px-[2.6rem]">
          <Swiper
                loop={true}
                spaceBetween={10}
                slidesPerView="1"
                // centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                speed="1500"
                // autoHeight={true}
                modules={[Autoplay]}
                navigation={false}
                // pagination={{ clickable: true, }}
                // modules={[Autoplay, Pagination, Navigation]}
                className=""
                ref={homeTestimo}
          
                /* breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  548: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1600: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  2100: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                }} */
            >
              { testimonialItem.map((testiItem, index) => {
                return (
                <SwiperSlide key={index}>
                  <div className='relative'>
                    {/* <img className='' src={testiItem.source} alt='fram' /> */}
                    {/* <div className='absolute top-0 left-0 w-full h-full py-[6.9rem] px-[2.6rem]'> */}
                      <FaQuoteLeft className='m-auto mb-3' size="30" />
                      <p style={{fontSize: 'calc(0.795rem + 0.2vw)'}}>{testiItem.TestiDtl}</p>
                    {/* </div> */}
                    {/* <p className='text-[.8em] xl:text-[.8em] lg:text-[1em] sm:text-[.8em] xs:text-[.9em] absolute top-12 text-center px-4 py-[1.2em] lg:py-9 md:py-5 xs:py-[2em] left-1 text-black  flex-wrap'>{testiItem.TestiDtl.substr(0, 220)}</p> */}
                  </div>
                </SwiperSlide>
                )
              }) }
              {/* <SwiperSlide >
                <div className='relative'>
                  <img className='w-100 h-auto ' src={Fram} alt='fram' />
                  <p className='text-[9px]  absolute top-12 text-center p-4 left-1 text-black  flex-wrap'>Went there to shop for Pujo. Loved their collection. If you are looking to shop for ethnic clothes and sarees, Mabesha is the best choice. I bought an Ajrakh print cotton saree from an enviable collection of Ajrakh sarees. And I am absolutely in love with the saree I bought. I have been showered quite a lot of praises for this saree.
                    The staff was very helpful too. Can't wait to visit again.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide >
                <div className='relative'>
                  <img className='w-100 h-auto ' src={Fram} alt='fram' />
                  <p className='text-[9px]  absolute top-12 text-center p-4 left-1 text-black  flex-wrap'>Huge collection of authentic sarees and dress materials. Easily accessible with bustling crowds and shoppers. Reasonable price guaranteed quality materials
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide >
                <div className='relative'>
                  <img className='w-100 h-auto ' src={Fram} alt='fram' />
                  <p className='text-[9px]  absolute top-12 text-center text-black p-4 left-1 h-[140px] overflow-clip  '>This authentic saree boutique is located just 100m from Basanti Devi college busstop. Has large collection of sarees of various ranges starting from 600 to 15000. You would love each and every bit of their collection. Staffs are great and will guide you choosing the best saree within your budget. I got least knowledge about sarees but they guided me well, and I ended up buying a total of 3 sarees from their store. Bought one super net that costs 1850, one handloom sarees of 1550 INR and a taant of 890 INR.  You can pay by either card or cash, exchange is entertained within 7 days of purchase.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide >
                <div className='relative'>
                  <img className='w-100 h-auto' src={Fram} alt='fram' />
                  <p className='text-[9px]  absolute top-12 text-center text-black p-4 left-1 overflow-x-auto  flex-wrap'>Absolutely love the collection of sarees, brilliant range at very good prices. The sales staff is very cooperative and friendly. My mom loves the sarees from they. The range of cottons is absolutely stunning
                  </p>
                </div>
              </SwiperSlide> */}
          
            </Swiper>
        </div>
      </div>  
        
        
        


        {/* <svg className='w-10 h-10 absolute top-[50%] left-0 rounded-full opacity-50 shadow-sm cursor-pointer p-1 text-[#8C6940] font-extrabold  bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        <svg className='w-10 h-10 absolute top-[50%] right-0 rounded-full opacity-50 shadow-sm cursor-pointer p-1 text-[#8C6940] font-extrabold  bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg> */}

      </div>
    </div>
  )
}
const  Slider = memo(SliderMemorize)
export default Slider