import React, { useState } from 'react'
import logo from '../../assets/Logo/Logo.png'
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
const ReSet = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const hashcode = searchParams.get("hash");
    const [userPassword, setUserpassword] = useState()
    const [confirmUserPassword, setConfirmUserPassword] = useState()
    console.log("This is reset hash", hashcode)
    const payload = {
        hash: hashcode,
        new_password: confirmUserPassword,
    }
    const passwordResetHandler = () => {
        if (userPassword == confirmUserPassword) {
            axios.post(`https://mabesha.com/admin/api/resetpassword`,  payload )
                .then((res) =>{
                    NotificationManager.success(res.data. message)
                    navigate('/login')
                })
                .catch((err) => NotificationManager.error(err))
        } else {
            NotificationManager.error("Password and confirm password not match")
        }
    }


    return (
        <div>

            <div className="bg-red-500 text-white flex min-h-screen flex-col items-center pt-16 sm:justify-center sm:pt-0">
                <a href="#">
                    <div className="text-foreground font-semibold text-2xl tracking-tighter mx-auto flex items-center gap-2">
                        <div>

                            <img src={logo} alt="logo" />
                        </div>

                    </div>
                </a>
                <div className="relative mt-12 w-full max-w-lg sm:mt-10">
                    <div className="relative -mb-px h-px w-full bg-gradient-to-r from-transparent via-sky-300 to-transparent"
                        bis_skin_checked="1"></div>
                    <div
                        className="mx-5 border dark:border-b-white/50 dark:border-t-white/50 border-b-white/20 sm:border-t-white/20 shadow-[20px_0_20px_20px] shadow-slate-500/10 dark:shadow-white/20 rounded-lg border-white/20 border-l-white/20 border-r-white/20 sm:shadow-sm lg:rounded-xl lg:shadow-none">
                        <div className="flex flex-col p-6">
                            <h3 className="text-xl font-semibold leading-6 tracking-tighter">Reset Password</h3>
                            <p className="mt-1.5 text-sm font-medium text-white/50">Welcome back, enter your password and confirm password.
                            </p>
                        </div>
                        <div className="p-6 pt-0">
                            <div>
                                <div>
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-white text-gray-400">Password</label>
                                                <div className="absolute right-3 translate-y-2 text-green-200">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                        fill="currentColor" className="w-6 h-6">
                                                        <path fill-rule="evenodd"
                                                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <input type="Password" name="Password" placeholder="Password"
                                                autocomplete="off"
                                                value={userPassword}
                                                onChange={(e) => setUserpassword(e.target.value)}
                                                className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 file:rounded-full file:border-0 file:bg-accent file:px-4 file:py-2 file:font-medium placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 sm:leading-7 text-foreground" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div>
                                        <div
                                            className="group relative rounded-lg border focus-within:border-sky-200 px-3 pb-1.5 pt-2.5 duration-200 focus-within:ring focus-within:ring-sky-300/30">
                                            <div className="flex justify-between">
                                                <label
                                                    className="text-xs font-medium text-muted-foreground group-focus-within:text-white text-gray-400">Confirm Password</label>
                                            </div>
                                            <div className="flex items-center">
                                                <input type="password" name="password"
                                                    value={confirmUserPassword}
                                                    onChange={(e) => setConfirmUserPassword(e.target.value)}
                                                    className="block w-full border-0 bg-transparent p-0 text-sm file:my-1 placeholder:text-muted-foreground/90 focus:outline-none focus:ring-0 focus:ring-teal-500 sm:leading-7 text-foreground" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="mt-4 flex items-center justify-between">

                                    <Link to={'/login'} className="text-sm font-medium text-foreground underline" href="/forgot-password">Go Back To Login Page</Link>
                                </div>
                                <div className="mt-4 flex items-center justify-end gap-x-2">
                                    <Link className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:ring hover:ring-white h-10 px-4 py-2 duration-200"
                                        to="/signup">Register</Link>
                                    <button onClick={passwordResetHandler}
                                        className="font-semibold hover:bg-red-500 hover:text-white hover:ring hover:ring-white transition duration-300 inline-flex items-center justify-center rounded-md text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-white text-red-500 h-10 px-4 py-2"
                                    >Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReSet