import React, { memo, useEffect, useState } from 'react'

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RxDragHandleDots2 } from "react-icons/rx";
import { FaAngleDown } from "react-icons/fa6";


import saree from '../../assets/ProductGallary/Mabesha2442.jpg'
import axios from 'axios'
import { apiBaseUrl } from '../../config/config'
import { getFromLocalStorage, setToLocalStorage } from "../../helpers/helpers";
import { Link, useNavigate } from 'react-router-dom';
import AccItem from '../../components/accordian/AccItem';
import { useRef } from 'react';
import { FaAngleRight } from 'react-icons/fa';


function PastOrderListMemorize({className}) {
    const navigate = useNavigate()
    const [token, setToken] = useState(getFromLocalStorage('access_token'));
    const [imgPath, setImgPath] = useState()
    const [pastOrder, setPastorder] = useState()
    const [isVisibility, setIsVisibility] = useState({position: 0, visibility: false})

    
    const [activeIndex, setActiveIndex] = useState(null); // Index of the open accordion

    const [actionBtn, setActionBtn] = React.useState(null);
    
    const [menuId, setMenuId] = React.useState();
    const orderAction = Boolean(actionBtn);
    const orderActionHandleClick = (ev, id) => {
        // console.log(menuNode.current)
        setMenuId(id)
        // console.log("ev.currentTarget",ev.currentTarget)
        setActionBtn(ev.currentTarget);
        // setActionBtn(menuNode.current);
        // console.log('SD first', cardId, ev.currentTarget, actionBtn)
        // setActionBtn(cardId === actionBtn ? null : ev.currentTarget);
    };
    // const orderActionHandleClose = () => {
    //     setActionBtn(null);
    // };

    const waReturnLink = (getID) => {
        // console.log('WhatsApp Link 1', getID)
        const waLink = `https://wa.me/+918697659729?text=Hello%20my%20Order%20no%20is%20*%23${getID }*%20and%20I%20want%20to%20return%20it`
        window.location.href = waLink;
    }

    const handleItemClick = (index) => {
        var orderItems= pastOrder
        orderItems[index].isOpen =orderItems[index]?.isOpen? !orderItems[index]?.isOpen : true
        setPastorder([...orderItems])
    };
    useEffect(() => {
        // console.log("orderobject",pastOrder)
    }, [pastOrder])
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const getPastOrder = () => {
        axios.get(`${apiBaseUrl}api/orderhistory`, { headers })
            .then((res) => {
                // console.log("This is past order", res?.data)
                setImgPath(res?.data?.img_path)
                setPastorder(res?.data?.data)
            })
            .catch((err) => console.log("This error at thie time when fetch past order history",err))
    }
    useEffect(() => {
        getPastOrder()
    }, [])
    const viewOrder = (data,order_status,ed) =>{

        setToLocalStorage('orderList', JSON.stringify(data))
        setToLocalStorage('order_status', order_status)
        setToLocalStorage('ed', ed)
        navigate('/view-order')
    }

    const printOrder = (pastOrderItem) =>{
        setToLocalStorage('pastOrderItem', JSON.stringify(pastOrderItem));
        console.log('pastOrderItem',pastOrderItem);
        navigate('/../invoice')
    }

    return (
        <div  className={`${className}`}>
            {/* <div className='flex justify-center mb-5'>
                <input className='border border-red-300 p-1 text-sm h-8 w-full focus:outline-none rounded-tl-sm rounded-bl-sm' type='text' placeholder='search...' />
                <button className='text-sm text-white bg-[#D40E14] p-1 rounded-tr-sm rounded-br-sm w-28 hover:bg-red-500'>serach</button>
            </div> */}
            <div className="accordions">
                {pastOrder?.map((item, index) => {
                    var isVisible=0
                    let date = new Date(item.created_at)
                    const formattedDate = date.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                    });
                      
                    let oderStatus = item.order_status;
                    var isSuccessPay=item.payment_status=="Success" 
                    let expectedDate = new Date(item.created_at)
                    // console.log('expectedDate',item)
                    return (
                        <AccItem
                            key={index}
                            orderID={'#'+ item.order_id}
                            isOpen={index === activeIndex}
                            onClick={() => {}}          
                        >
                            <AccItem.Header className="flex items-center p-4" >
                                <div className="order-state">
                                    <span>{'#'+ item.order_id} - {formattedDate} </span><br />
                                    {/* <b>Order Status :</b> <span className=''> {oderStatus == 0 ? <span className='text-yellow-500'>Pending</span> :oderStatus == 1 ? <span className='text-green-500'>Confirmed</span> : oderStatus == 2 ? <span className='text-red-600'>Canceled</span>: <span className='text-green-500'>Delivered	</span>}</span> */}

                                    <b>Order Status :</b> {isSuccessPay?<><span className=''> {oderStatus == 0 ? <span className='text-yellow-500'>Pending</span> :oderStatus == 1 ? <span className='text-green-500'>Confirmed</span> : oderStatus == 2 ? <span className='text-yellow-500'>In-Transit</span> : oderStatus == 3 ? <span className='text-red-600'>Cancel</span> : <span className='text-green-500'>Delivered</span>}</span></>:<><span className='text-red-600'>{item.payment_status}</span></>} 
                                </div>
                                {/* <button onClick={()=>printOrder(item)} className='btn ml-auto min-h-fit h-auto py-[.8em]'>Invoice</button>
                                <Link to={'/trackingorder'} className='btn ml-[1em] min-h-fit h-auto py-[.8em]'>Track Order</Link> */}
                                <IconButton 
                                    id={`card-menu-btn-${index}`}
                                    className='!ml-auto' 
                                    aria-controls={orderAction ? 'basic-menu-'+{index} : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={orderAction ? 'true' : undefined}
                                    onClick={(ele)=>{orderActionHandleClick(ele, index)}}
                                >
                                    <RxDragHandleDots2 className='text-[1.5rem]' />
                                </IconButton>
                                <IconButton  onClick={() => {handleItemClick(index)}}><FaAngleRight className={"text-[1.5rem] transition-all duration-[.2s] ease-in-out" + (item?.isOpen===true ? " rotate-90":"")} /></IconButton>
                                <Menu
                                    id={`card-menu-${index}`}
                                    anchorEl={actionBtn}
                                    open={menuId===index}
                                    onClose={()=>setMenuId()}
                                    MenuListProps={{
                                        'aria-labelledby': `card-menu-btn-${index}`,
                                    }}
                                >
                                    <MenuItem onClick={()=>printOrder(item)}>Invoice</MenuItem>
                                    <MenuItem onClick={()=>navigate('/trackorder/'+item.order_id)}>Track Order</MenuItem>
                                    <MenuItem><div onClick={()=>waReturnLink(item.order_id)}>Return Order</div></MenuItem>
                                </Menu>
                            </AccItem.Header>
                            <AccItem.Body   visibility={(item?.isOpen===true)?"":"hidden"}>
                              {
                                item.products?.map((itemSub, index)=>{
                                    const newImg = itemSub.image ? itemSub.image.split(",") : [];
                                
                                    return(
                                    <div key={index} className='w-full border rounded-sm bg-white flex justify-between md:px-4 py-2 items-center'>
                                        <div className='flex flex-col md:flex-row justify-between'>
                                            <img className='h-auto w-20' src={`https://mabesha.com/admin/storage/product/product_logo/product_image/${newImg[0]}`} alt='saree' />
                                            <div className='md:ml-2'>
                                                <p className='text-black'>{itemSub?.name}</p>
                                                {itemSub?.color ? <p>Colour : {itemSub?.color}</p>:''}
                                                <p>Qrt: {itemSub?.quantity}</p>
                                                <small>{item.address}</small>
                                            </div>
                                        </div>
                                        {/* <p>₹ {itemSub?.price}</p> */}
                                        
                                        {/* <div>
                                            <div className='flex  justify-center items-center space-x-2'>
                                                <p className='text-md font-semibold'>Order status : <span className=''> {orderStatus == 0 ? <span className='text-yellow-500'>pending</span> :orderStatus == 1 ? <span className='text-green-500'>confirm</span> : orderStatus == 1 ? <span className='text-red-600'>cancel</span>: <span className='text-green-500'>delivered	</span>}</span></p>
                                            </div>
                                            <p className='text-xs text-black mt-2 font-thin'>Estimated delivery : {ed}</p>
                                        </div> */}
                    
                                    </div>
                                    )
                                })
                              }
                            </AccItem.Body>
                            <AccItem.Footer className="p-4">
                                <div className="flex justify-between items-center">
                                    <p className="text-md flex flex-col"><small className='font-semibold'>Payment Method</small> <span className="font-normal text-black text-[1.1em]">{item.payment_mode? 
                                    (<>{item.payment_mode} - <span className='text-[#22c55e]'>{item.payment_status}</span></>) 
                                    : (<><span className='text-[#ec2028]'>{item.payment_status}</span></>)}
                                    </span></p>
                                    <p className="text-md flex flex-col text-right"><small className='font-semibold'>Due Amount</small> <span className="font-normal text-black text-[1.1em]">₹ {(item?.free_shipping==="1")?(item.amount_charged- item?.delivery_charge) :item?.amount_charged}</span></p>
                                </div>
                            </AccItem.Footer>
                        </AccItem>
                    )
                })}
            </div>

            {/* <div className='space-y-6'>
                {
                    pastOrder?.map((item, index) => {
                        let date = new Date(item.created_at)
                        let dateMDY = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                        let oderStatus = item.order_status;

                        return (
                            <div key={index} className='w-full h-36 border justify-around border-red-300 shadow-xl rounded-sm bg-white flex space-x-16 items-center'>
                                <div>
                                <button onClick={()=>printOrder(item)} className="text-sm bg-red-600 text-white font-semibold p-2 rounded-md flex  justify-around mb-3"> Print Invoice</button>
                                </div>
                                <div className='mx-5  flex space-x-10'>

                                    <div className='space-y-2'>
                                        <p className='text-md font-semibold' onClick={()=>viewOrder(item.products,item.order_status,item.expected_delivery)}>Order Id :<span className='font-normal text-black text-sm'> {item.order_id}</span></p>
                                        <p className='text-md font-semibold'>payment Id : <span className='font-normal text-black text-sm'>{item.payment_id}</span></p>
                                       {item.payment_status === "Success" ? <p className='text-md font-semibold'>payment status : <span className='text-green-500'> {item.payment_status}</span></p>:<p className='text-md font-semibold'>payment status : <span className='text-red-600'> {item.payment_status}</span></p>}
                                    </div>
                                </div>
                                <p>₹ {item.amount_charged}</p>
                                <div>
                                    <div className='flex  justify-center items-center space-x-2'>
                                       
                                        <p className='text-md font-semibold'>Order status : <span className=''> {oderStatus == 0 ? <span className='text-yellow-500'>pending</span> :oderStatus == 1 ? <span className='text-green-500'>confirm</span> : oderStatus == 2 ? <span className='text-red-600'>cancel</span>: <span className='text-green-500'>delivered	</span>}</span></p>
                                    </div>
                                    <p className='text-xs text-black mt-2 font-thin'>Estimated delivery : {item.expected_delivery}</p>
                                </div>
                            </div>
                        )
                    })
                }

            </div> */}
        </div>
    )
}
const PastOrderList = memo(PastOrderListMemorize)
export default PastOrderList
